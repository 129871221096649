import { useEffect, useState } from "react";
import React from 'react';
import Footer from "../components/footer";
import { Link } from "react-router-dom";

export default function MainPage() {

    const [showTopText, setShowTopText] = useState(false);
    const [showBottomText, setShowBottomText] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [showImmediately, setShowImmediately] = useState(false);
    const [redirectToGoogle, setRedirectToGoogle] = useState(false);

    setTimeout(() => {
        if(!showBottomText) setShowBottomText(true);
    }, 3200);
    setTimeout(() => {
        if(!showTopText) setShowTopText(true);
    }, 1200);
    setTimeout(() => {
        if(!showButtons) setShowButtons(true);
    }, 5200);

    useEffect(()=> {
        document.body.addEventListener('click', ()=>{
            setShowTopText(true);
            setShowBottomText(true);
            setShowButtons(true);
            setShowImmediately(true);
        })
    }, [])

    return (
        <>
            <div className="background"></div>
            <div className="main_wrapper">
                <div className="main">
                    <p className={`top_text ${showTopText ? "active" : ''} ${showImmediately ? "no_animation" : ''}`}>
                        Медитации.
                    </p>
                    <h1 className={`bottom_text ${showBottomText ? "active" : ''} ${showImmediately ? "no_animation" : ''}`}>
                        Энергия.
                    </h1>
                    <div
                        className={`button_wrapper ${showButtons ? "active" : ''} ${showImmediately ? "no_animation" : ''}`}
                    >
                        
                        <Link to='https://apps.apple.com/ua/app/energy-of-meditations/id6464591640' target="_blank" className="main_button">
                            Установить на IOS
                        </Link>
                        <Link to="https://play.google.com/store/apps/details?id=com.app.Energy" target="_blank" className="main_button">
                            Установить на ANDROID
                        </Link>
                    </div>
                    <div className={`button_wrapper single ${showButtons ? "active" : ''} ${showImmediately ? "no_animation" : ''}`}>
                        <Link to='/login' target="_blank" className="main_button">
                            Веб-версия
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
