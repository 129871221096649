import React, {useContext, useEffect, useState} from "react";
import Select from "react-dropdown-select";
import {
    NavLink,
    Navigate,
    Outlet,
    matchRoutes,
    useLocation,
    useParams, useOutletContext,
} from "react-router-dom";
import { sendFetch } from "../utils/fetch";
import {AdminContext} from "../components/adminContextProvider";

export default function LessonCreate() {
    const [cover, setCover] = useState();
    const [coverPhoto, setCoverPhoto] = useState();
    const [coverName, setCoverName] = useState("");

    const [linkValue, setLinkValue] = useState("");
    const [nameValue, setNameValue] = useState("");

    const [role, setRole] = useState(0);
    const [isLesson, setIsLesson] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const { roles } = useContext(AdminContext);


    const postSignup = async (data) => {
        try {
            const response = await sendFetch(
                "admin/lessons/create-lesson",
                {
                    method: "POST",
                    body: data,
                }
            );
            if (response && response?.status === 200) {
                setSuccessful(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formSubmit = () => {
        if (nameValue.length > 0 && linkValue.length > 0 && cover) {
            const formData = new FormData();
            formData.append("image", cover);
            formData.append("name", nameValue);
            formData.append("video", linkValue);
            formData.append("role", role);
            formData.append("isLesson", isLesson);
            postSignup(formData);
        } else {
            if (linkValue.length == 0) {
                // add errors
            }
            if (nameValue.length == 0) {
            }
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const trackRoute = e.target.value.split("\\") || "";
        const trackName = trackRoute[trackRoute.length - 1] || "a a a a";
        let trackExtension = trackName.split(".");

        trackExtension = trackExtension[trackExtension.length - 1];
        if (file) {
            const reader = new FileReader();
            setCoverName(trackName);
            const modifiedCoverFile = new File(
                [file],
                file.name.replaceAll(" ", ""),
                { type: file.type }
            );
            //console.log(modifiedCoverFile);
            setCover(modifiedCoverFile);
            setCoverName(trackExtension);
            reader.onload = (e) => {
                setCoverPhoto(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    if (successful) {
        return <Navigate to='/admin/content/lessons' replace={true} />
    }

    return (
        <>
            <div className="user_wrapper">
                <div className="user_avatar_wrapper meditation">
                    <span
                        className="user_title"
                        style={{ marginBottom: 10 + "px" }}
                    >
                        Обложка
                    </span>
                    <div className="user_avatar video">
                        <img
                            className="user_avatar_img"
                            src={
                                coverPhoto
                                    ? coverPhoto
                                    : require("../img/avatar.jpg")
                            }
                            alt="Meditation cover"
                        />
                    </div>
                    <input
                        className="meditation_file_input"
                        accept=".jpg, .jpeg, .png, .heic"
                        id="coverInput"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label
                        className="admin_login_button meditation_file_label"
                        htmlFor="coverInput"
                    >
                        Выбрать файл
                    </label>
                    <span className="admin_small_text">
                        *В формате .png, .jpg, .jpeg и .heic
                    </span>
                </div>
                <div className="user_line_wrapper">
                    <div className="user_title">Название</div>
                    <input
                        style={{ maxWidth: 500 + "px" }}
                        className="admin_input"
                        value={nameValue}
                        onChange={(e) => {
                            setNameValue(e.target.value);
                        }}
                    />

                    <div className="user_title">Ссылка</div>
                    <input
                        style={{ maxWidth: 500 + "px" }}
                        className="admin_input"
                        value={linkValue}
                        onChange={(e) => {
                            setLinkValue(e.target.value);
                        }}
                    />

                    <div className="user_title">Порядок</div>
                    <Select
                        separator
                        dropdownHandle
                        closeOnSelect
                        color="#45aff3"
                        options={roles}
                        labelField="label"
                        valueField="value"
                        searchable={false}
                        values={roles.filter((item) => {
                            //console.log(item.value);
                            //console.log(video);
                            //console.log("yo");
                            if (item.value === 0) {
                                return item;
                            }
                        })}
                        style={{
                            maxWidth: 500 + "px",
                            border: "1px solid #05071d",
                            borderRadius: 8 + "px",
                            height: "41px",
                            padding: 10 + "px",
                        }}
                        onChange={(items) => {
                            setRole(items[0].value);
                        }}
                    />

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            onClick={formSubmit}
                            className="admin_login_button"
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
