import axios from "axios";
import {api} from "../config/endpoints";
import {getValidTokenFromStorage} from "./jwt";

const api1 = `${api}`;
//const api1 = "https://localhost:44387";
const api2 = "https://api-energy-meditation.astin.io";

export async function sendFetch(link, options) {
    let response = null;
    try {
        let response1 = null;
        await axios({
            method: options.method || "GET",
            url: api1 + link,
            data: options.body,
            headers: {...options.headers, Authorization: `Bearer ${getValidTokenFromStorage()}` },
        }).then((response) => {
            response1 = response;
        });

        if (response1.status === 400 || response1.status === 200) {
            console.log(response1);
            response = response1;
        } else {
            throw new Error("Api request failed");
        }
    } catch (e) {
        try {
            let response2 = null;
            await axios({
                method: options.method || "GET",
                url: api2 + link,
                data: options.body,
                headers: {...options.headers, Authorization: `Bearer ${getValidTokenFromStorage()}` },
            }).then((response) => {
                response2 = response;
            });
            if (response2.status === 400 || response2.status === 200) {
                response = response2;
            }
        } catch (error) {
            // Handle any errors that occurred during the Axios requests
            //console.error('Axios request failed:', error);
        }
    } finally {
        return response;
    }
}
