import { useState } from "react";
import React from "react";
import "../css/styles.css";
import { Form, Link, Navigate, redirect, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Checkbox, FormControlLabel } from "@mui/material";
import { sendFetch } from "../utils/fetch";

let passwordErrorCapital = "Должен иметь заглавные буквы. ";
let passwordErrorLength = "Должен быть 8 символов в длину или больше. ";
let passwordErrorNumbers = "Должен иметь цифры. ";
let loginErrorText = "Введите правильную электронную почту.";
let repeatPasswordErrorText = "Пароли не совпадают. ";
let wrongLogin = "Неверный логин или пароль";

function SignupPage() {
    const [loginVal, setLoginVal] = useState("");
    const [passwordVal, setPasswordVal] = useState("");
    const [repeatPasswordVal, setRepeatPasswordVal] = useState("");
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");
    const [privacyError, setPrivacyError] = useState(false);
    const [privacyCheck, setPrivacyCheck] = useState(false);
    const [secondHalf, setSecondHalf] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [firstNameVal, setFirstNameVal] = useState('');
    const [lastNameVal, setLastNameVal] = useState('');
    const navigate = useNavigate();

    const checkLogin = (checked = loginVal) => {
        const regEmail = new RegExp(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
        );
        if (regEmail.test(checked)) {
            setLoginError("");
            return true;
        } else {
            setLoginError(loginErrorText);
            return false;
        }
    };
    const checkPassword = (checked = passwordVal) => {
        const regPassword = new RegExp(
            /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/
        );
        let currentPasswordError = "";

        if (regPassword.test(checked)) {
            setPasswordError("");
            return true;
        } else {
            if (checked.length < 8) {
                currentPasswordError =
                    currentPasswordError + passwordErrorLength;
            }
            if (!/[A-Z]/.test(checked)) {
                currentPasswordError =
                    currentPasswordError + passwordErrorCapital;
            }
            if (!/\d/.test(checked)) {
                currentPasswordError =
                    currentPasswordError + passwordErrorNumbers;
            }

            setPasswordError(currentPasswordError);
            return false;
        }
    };
    const checkRepeatPassword = (password1, password2) => {
        if (password1 != password2) {
            setRepeatPasswordError(repeatPasswordErrorText);
            return false;
        } else {
            setRepeatPasswordError("");
            return true;
        }
    };

    const handleLoginChange = (e) => {
        if (loginError.length != 0) {
            checkLogin(e.target.value);
        }
        setLoginVal(e.target.value);
    };
    const handlePasswordChange = (e) => {
        if (passwordError.length != 0) {
            checkPassword(e.target.value);
        }
        if (repeatPasswordError.length != 0) {
            checkRepeatPassword(e.target.value, repeatPasswordVal);
        }
        setPasswordVal(e.target.value);
    };
    const handleRepeatPasswordChange = (e) => {
        if (repeatPasswordError.length != 0) {
            checkRepeatPassword(passwordVal, e.target.value);
        }
        setRepeatPasswordVal(e.target.value);
    };

    const HandleForm = (e) => {
        e.preventDefault();
        setPasswordError("");
        setLoginError("");
        let loginVar = checkLogin();
        let passwordVar = checkPassword();
        let repeatPasswordVar = checkRepeatPassword(
            passwordVal,
            repeatPasswordVal
        );
        let privacyVar = false;
        if (privacyCheck) {
            privacyVar = true;
            setPrivacyError(false);
        } else {
            setPrivacyError(true);
        }
        if (loginVar && passwordVar && repeatPasswordVar && privacyVar) {
            setSecondHalf(true);
        }
    };
    const HandleSecondForm = (e) => {
        e.preventDefault();
        if(firstNameVal.length == 0) {
            setFirstNameError(true);
            return
        }
        if(lastNameVal.length == 0) {
            setLastNameError(true);
            return
        }
        let data = {
            firstName: firstNameVal,
            lastName: lastNameVal,
            email: loginVal,
            password: passwordVal
        }
        postSignup(data);
    }
    const handleFirstNameChange = (e) => {
        if(firstNameError) {
            if(e.target.value.length > 0) {
                setFirstNameError(false);
            }
        }
        setFirstNameVal(e.target.value);
    }
    const handleLastNameChange = (e) => {
        if(lastNameError) {
            if(e.target.value.length > 0) {
                setLastNameError(false);
            }
        }
        setLastNameVal(e.target.value);
    }

    const postSignup = async (data) => {
        try {
            const response = await sendFetch(
                "user/register",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            if(response && response?.status === 200) {
                navigate('/login', { replace: true });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div
            className="login_body"
            style={{ color: "black", position: "relative", minHeight: "100vh" }}
        >
            <div className="app_background"></div>
            <div>
                <form className="admin_login_wrapper">
                    <h1 className="big_title">Регистрация</h1>
                    {!secondHalf ? (
                        <>
                            <div className="admin_input_wrapper">
                                <span className="admin_login_text">Логин</span>
                                <input
                                    placeholder="Введите почту"
                                    value={loginVal}
                                    style={{
                                        borderColor: loginError
                                            ? "red"
                                            : "#05071d",
                                    }}
                                    onChange={handleLoginChange}
                                    autoComplete="email"
                                    className="admin_input"
                                    id="adminLogin"
                                />
                                {loginError && (
                                    <span className="small_text error_text">
                                        {loginError}
                                    </span>
                                )}
                            </div>
                            <div className="admin_input_wrapper password">
                                <span className="admin_login_text">Пароль</span>
                                <input
                                    placeholder="Придумайте пароль"
                                    value={passwordVal}
                                    style={{
                                        borderColor: passwordError
                                            ? "red"
                                            : "#05071d",
                                    }}
                                    onChange={handlePasswordChange}
                                    className="admin_input"
                                    autoComplete="new-password"
                                    id="adminPassword"
                                    type="password"
                                />
                                {passwordError && (
                                    <span className="small_text error_text">
                                        {passwordError}
                                    </span>
                                )}
                            </div>
                            <div className="admin_input_wrapper password">
                                <input
                                    placeholder="Повторите пароль"
                                    value={repeatPasswordVal}
                                    onChange={handleRepeatPasswordChange}
                                    style={{
                                        borderColor: repeatPasswordError
                                            ? "red"
                                            : "#05071d",
                                    }}
                                    autoComplete="new-password"
                                    className="admin_input"
                                    id="adminRepeatPassword"
                                    type="password"
                                />
                                {repeatPasswordError && (
                                    <span className="small_text error_text">
                                        {repeatPasswordError}
                                    </span>
                                )}
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: privacyError
                                                ? "red"
                                                : "rgb(255,255,255)",
                                            "&.Mui-checked": {
                                                color: "rgb(204,0,222)",
                                            },
                                        }}
                                        checked={privacyCheck}
                                        onChange={() => {
                                            if (!privacyCheck)
                                                setPrivacyError(false);
                                            setPrivacyCheck(!privacyCheck);
                                        }}
                                    />
                                }
                                sx={{
                                    fontFamily: "Jost, sans-serif!important",
                                    "& span": {
                                        fontFamily:
                                            "Jost, sans-serif!important",
                                        lineHeight: "18px!important",
                                    },
                                    "& a": {
                                        textDecoration: "underline",
                                    },
                                }}
                                label={
                                    <span className="normal_text">
                                        Соглашаюсь с условиями{" "}
                                        <a
                                            target="_blank"
                                            href="/privacy_policy"
                                        >
                                            Политики Конфиденциальности
                                        </a>{" "}
                                        и{" "}
                                        <a target="_blank" href="/terms_of_use">
                                            Правил Использования
                                        </a>
                                    </span>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <div className="admin_input_wrapper">
                                <span className="admin_login_text">Имя</span>
                                <input
                                    placeholder=""
                                    value={firstNameVal}
                                    style={{
                                        borderColor: firstNameError
                                            ? "red"
                                            : "#05071d",
                                    }}
                                    onChange={handleFirstNameChange}
                                    autoComplete="given-name"
                                    className="admin_input"
                                />
                            </div>
                            <div className="admin_input_wrapper">
                                <span className="admin_login_text">Фамилия</span>
                                <input
                                    placeholder=""
                                    value={lastNameVal}
                                    style={{
                                        borderColor: lastNameError
                                            ? "red"
                                            : "#05071d",
                                    }}
                                    onChange={handleLastNameChange}
                                    autoComplete="family-name"
                                    className="admin_input"
                                />
                            </div>
                        </>
                    )}
                    <button
                        onClick={!secondHalf ? HandleForm : HandleSecondForm}
                        className="admin_login_button "
                    >
                        Создать аккаунт
                    </button>
                    <div className="login_link_wrapper">
                        <Link to="/login" className="medium_title">
                            Войти
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default SignupPage;
