import React, { useEffect, useState } from "react";
import {api} from "../../config/endpoints";

export default function PanelSupportPage() {
    const [textValue, setTextValue] = useState("");
    const [subjectValue, setSubjectValue] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState('');
    const [textError, setTextError] = useState(false);

    async function sendMessage() {
        let loginCheck = checkLogin();
        let subjectCheck = checkSubject();
        let textCheck = checkText();
        if (loginCheck && subjectCheck && textCheck) {
            try {
                const response = await fetch(
                    `${api}email/feedback`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            email: emailValue,
                            theme: subjectValue,
                            text: textValue,
                        }),
                    }
                );
                if (response.status != 200) {

                } else {
                    setEmailValue("");
                    setSubjectValue("");
                    setTextValue("");
                    alert('Ваше сообщение успешно отправлено!')
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
    const checkLogin = (checked = emailValue) => {
        const regEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/);
        if (regEmail.test(checked)) {
            setEmailError(false);
            return true;
        } else {
            setEmailError(true);
            return false;
        }
    };
    const checkSubject = (checked = subjectValue) => {
        if (checked.length > 0) {
            setSubjectError(false);
            return true;
        } else {
            setSubjectError(true);
            return false;
        }
    };
    const checkText = (checked = textValue) => {
        if (checked.length > 0) {
            setTextError(false);
            return true;
        } else {
            setTextError(true);
            return false;
        }
    };
    const emailChange = (e) => {
        if (emailError) {
            checkLogin(e.target.value);
        }
        setEmailValue(e.target.value);
    };
    const subjectChange = (e) => {
        if (subjectError) {
            checkSubject(e.target.value);
        }
        setSubjectValue(e.target.value);
    };
    const textChange = (e) => {
        if (textError) {
            checkText(e.target.value);
        }
        setTextValue(e.target.value);
    };

    return (
        <section className="panel_list_section">
            <div className="meditations_list_wrapper padding">
                <h2 className="big_title title_nowrap">Обратная связь</h2>
                <div className="support_wrapper">
                <div className="support_input_wrapper">
                        <span className="normal_text">Ваш адрес эл. почты</span>
                        <input
                            maxLength={200}
                            className="admin_input"
                            value={emailValue}
                            onChange={emailChange}
                            style={{
                                borderColor: emailError
                                    ? "red"
                                    : "#05071d",
                            }}
                        />
                    </div>
                    <div className="support_input_wrapper">
                        <span className="normal_text">Тема сообщения</span>
                        <input
                            maxLength={200}
                            className="admin_input"
                            value={subjectValue}
                            onChange={subjectChange}
                            style={{
                                borderColor: subjectError
                                    ? "red"
                                    : "#05071d",
                            }}
                        />
                    </div>
                    <div className="support_input_wrapper">
                        <span className="normal_text">Сообщение</span>
                        <textarea
                            maxLength={500}
                            className="admin_input"
                            value={textValue}
                            onChange={textChange}
                            style={{
                                borderColor: textError
                                    ? "red"
                                    : "#05071d",
                            }}
                        />
                        <div className="list_small_text_wrapper">
                            <span className="normal_text">{textValue.length}/500</span>
                        </div>
                    </div>
                    <button onClick={sendMessage} className="admin_login_button support_button">Отправить</button>
                </div>
            </div>
        </section>
    );
}
