import React, { useContext } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";

import { ReactComponent as ReportSvg } from "../img/report.svg";
import { ReactComponent as EditSvg } from "../img/edit.svg";
import { ReactComponent as InfoSvg } from "../img/info.svg";
import { useEffect } from "react";
import { sendFetch } from "../utils/fetch";
import { useState } from "react";
import { AdminContext } from "../components/adminContextProvider";

export default function UserInfo() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = location.state?.searchParams || "";
  const [user, setUser] = useState(null);
  const { roles } = useContext(AdminContext);
  useEffect(() => {
    const getUserData = async () => {
      const response = await sendFetch(`user/get-user-info?userId=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response && response?.status === 200) {
        setUser(response.data);
      }
    };
    getUserData();
  }, []);
  const Separator = () => {
    return <div className="separator"></div>;
  };
  return (
    <section className="users_section">
      <h1>Пользователи и роли</h1>
      <div className="users_list_info_wrapper">
        <div className="users_list_user_wrapper">
          <div className="users_list_left">
            <span className="users_list_user_name">
              {`${user?.firstName || "..."} ${user?.lastName || ""}`}
            </span>
            <Link
              replace={true}
              className="users_list_user_button"
              state={{ searchParams: searchParams }}
              to={`/admin/users/${id}/edit`}
            >
              <EditSvg />
            </Link>
            <Link
              replace={true}
              className="users_list_user_button"
              state={{ searchParams: searchParams }}
              to={`/admin/users/${id}`}
            >
              <InfoSvg />
            </Link>
          </div>
          <div className="users_list_right">
            <a
              style={{ display: "none" }}
              className="users_list_user_button"
              href="/"
            >
              <ReportSvg />
            </a>
            <NavLink
              className="content_navigation_link"
              to="gratitude_list"
              state={{ searchParams: searchParams }}
              replace={true}
            >
              Благодарности
            </NavLink>
            <Separator />
            <NavLink
              className="content_navigation_link"
              to="intention_list"
              state={{ searchParams: searchParams }}
              replace={true}
            >
              Намерения
            </NavLink>
            <Separator />
            <NavLink
              className="content_navigation_link"
              to="day_goals"
              state={{ searchParams: searchParams }}
              replace={true}
            >
              Задачи на день
            </NavLink>
            <Separator />
            <NavLink
              className="content_navigation_link"
              to="year_goals"
              state={{ searchParams: searchParams }}
              replace={true}
            >
              Цели на год
            </NavLink>
          </div>
        </div>
        <Outlet context={[user, searchParams, roles]} />
      </div>
    </section>
  );
}
