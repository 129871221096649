import React from "react";
import { Outlet, useOutletContext} from "react-router-dom";

export default function PackageOutlet() {
    return (
        <section className="users_section">
            <h1>Пакеты</h1>
            <Outlet/>
        </section>
    );
}
