import React, { useEffect, useState } from "react";
import {
    NavLink,
    Navigate,
    Outlet,
} from "react-router-dom";
import {ReactComponent as MainTabIcon} from "../img/maintab.svg";
import {ReactComponent as EducationTabIcon} from "../img/educationtab.svg";
import {ReactComponent as MeditationTabIcon} from "../img/meditationtab.svg";
import {ReactComponent as SettingsTabIcon} from "../img/settingstab.svg";
import Cookies from "universal-cookie";
import { sendFetch } from "../utils/fetch";
import {api} from "../config/endpoints";

export default function PanelLayout() {
    const [userData, setUserData] = useState({})
    const [avatar, setAvatar] = useState(null);
    const [logout, setLogout] = useState(false);
    const [meditationList, setMeditationList] = useState([]);
    const cookies = new Cookies();
    const token = cookies.get("user_token");
    useEffect(()=>{
        async function fetchUserData() {

            if (token) {
                try {
                    const response = await sendFetch(
                        `user/get-user-info?userId=${token}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    if (response && response?.status === 200) {
                        const json = response.data;
                        setUserData(json);
                        let formData = new FormData();
                        formData.append("userId", token);
                        // TODO: change to sendFetch and find a way to get image from axios response

                        const response2 = await fetch(
                            `${api}user/get-image`,
                            {
                                method: "POST",
                                body: formData,
                            }
                        );
                        if(response2.status === 200){
                            const blob = await response2.blob();
                            const reader = new FileReader();

                            reader.onload = async () => {
                                const base64Data = reader.result;
                                setAvatar(base64Data);
                            };
                            reader.readAsDataURL(blob);
                        }
                    } else {
                        const cookies = new Cookies();
                        cookies.remove("user_token", {
                            path: "/",
                        });
                        setLogout(true);
                    }
                } catch (error) {
                    //console.error(error);
                }
            }
        }

        fetchUserData();
    }, [])

    useEffect(()=>{
        //console.log(token);
        const fetchTracks = async (userId) => {
            try {
                const response = await sendFetch(
                    `meditation/get-list-meditation-for-user?userId=${userId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const json = response?.data || [];
                let jsonAvailable = [];
                let jsonOther = [];

                json.forEach(async (item, index) => {
                    if (item.isAvailable) {
                        jsonAvailable.push(item);
                    } else {
                        item.lastCount = null;
                        jsonOther.push(item);
                    }
                });

                let jsonSorted = jsonAvailable.concat(jsonOther);
                setMeditationList(jsonSorted);
                //console.log(jsonSorted);
            } catch (error) {
                console.error(error);
            } finally {
            }
        };
        fetchTracks(token);
    },[])

    const logoutFunction = () => {
        const cookies = new Cookies();
        cookies.remove("user_token", {
            path: "/",
        });
        setUserData({});
        setAvatar(null);
        setLogout(true);
    }
    if (!token || logout) {
        return <Navigate to="/login" replace={true} />;
    }
    return (
        <>
            <div className="panel_layout">
                <div className="app_background"></div>
                <Outlet context={[userData, avatar, logoutFunction, meditationList]}/>
                <div className="panel_layout_tabs">
                    <NavLink to={'/panel'} end className='panel_layout_tab'><MainTabIcon/></NavLink>
                    <NavLink style={{display:'none'}} to={'/panel/education'} end className='panel_layout_tab'><EducationTabIcon/></NavLink>
                    <NavLink to={'/panel/meditations'} className='panel_layout_tab'><MeditationTabIcon/></NavLink>
                    <NavLink to={'/panel/settings'} className='panel_layout_tab'><SettingsTabIcon/></NavLink>
                </div>
            </div>
        </>
    );
}
