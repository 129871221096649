import React, {createContext, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {sendFetch} from "../utils/fetch";

export const AdminContext = createContext();

export default function AdminContextProvider({children}) {
    const [roles, setRoles] = useState([]);
    const [packages, setPackages] = useState([]);
    const location = useLocation();
    useEffect(()=> {
        const func = async (entity = "roles") => {
            if (location.pathname !== '/admin/login' && location.pathname !== '/admin') {
                try {
                    const response = await sendFetch(
                        `admin/get-${entity}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    entity === "roles" ? setRoles(response.data) : setPackages(response.data);
                } catch (e) {}
            }

        }
        func();
        func("packages");
    }, [location.pathname])

    return (
        <AdminContext.Provider value={{roles, setRoles, packages, setPackages}}>
            {children}
        </AdminContext.Provider>
    );
}
