import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { ReactComponent as EditSvg } from "../img/edit.svg";
import {
    Link,
    Navigate,
} from "react-router-dom";
import {api} from "../config/endpoints";
import {AdminContext} from "../components/adminContextProvider";

export default function PackageListPage() {
    const [authFail, setAuthFail] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const {roles, packages} = useContext(AdminContext);

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
    }, []);
    let filteredItems = [];
    let filteredPackages = [];

    if (roles.length > 1) {
        filteredItems = roles.filter((item) => {
            if (item.label.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
            }
        });
    }

    if (roles.length > 1) {
        filteredPackages = packages.filter((item) => {
            if (item.label.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
            }
        });
    }

    if (authFail) {
        return <Navigate to="/admin" />;
    }
    return (
        <section className="meditation_section">
            <div className="meditation_search_wrapper">
                <input
                    className="admin_input"
                    style={{ maxWidth: 400 + "px", margin: 0 }}
                    type="text"
                    placeholder="Поиск..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Link
                    to="create"
                    style={{ margin: 0 }}
                    className="admin_login_button meditation"
                >
                    Добавить
                </Link>
            </div>
            <div className="packages_list_wrapper">
                <div className="packages_list_col">
                    <div>Стардартные</div>
                    {filteredItems.map((item, index) => (
                        <PackageLine
                            pack={item}
                            key={index}
                            index={index}
                        />
                    ))}
                </div>
                <div className="packages_list_col">
                    <div>Мини</div>
                    {filteredPackages.map((item, index) => (
                        <PackageLine
                            pack={item}
                            key={index}
                            index={index}
                            mini={true}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

function PackageLine({ pack, index, mini }) {
    //console.log(meditation);
    return (
        <div className="users_list_user_wrapper">
            <Link
                to={`${pack.value}`}
                state={{ pack }}
                className="users_list_left"
            >
                <span className="users_list_user_id">{index + 1}.</span>
                <span className="users_list_user_name">{pack.label}</span>
            </Link>
            <div className="users_list_right">
                <Link
                    className="users_list_user_button"
                    to={`${mini ? "mini/" : "" }${pack.value}`}
                    state={{ pack }}
                >
                    <EditSvg />
                </Link>
            </div>
        </div>
    );
}
