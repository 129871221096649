import React from "react";
import { useState, useEffect, lazy, Suspense } from "react";
import "./App.css";
import "./css/styles.css";
import "./css/adaptation.css";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import MainPage from "./pages/mainPage";
import AdminLoginPage from "./pages/adminLoginPage";
import AdminUsersPage from "./pages/adminUsersPage/adminUsersPage";
import AdminLayout from "./components/adminLayout";
import UserInfo from "./pages/userInfo";
import UserDetails from "./pages/userDetails";
import UserEdit from "./pages/userEdit";
import ContentOutlet from "./pages/contentOutlet";
import MeditationListPage from "./pages/meditationListPage";
import MeditationEdit from "./pages/meditationEdit";
import VideoListPage from "./pages/videoListPage";
import VideoEdit from "./pages/videoEdit";
import MeditationCreate from "./pages/meditationCreate";
import VideoCreate from "./pages/videoCreate";
import GratitudeList from "./pages/gratitudeList";
import DailyList from "./pages/dailyList";
import YearlyList from "./pages/yearlyList";
import LoginPage from "./pages/loginPage";
import PanelLayout from "./components/panelLayout";
import PanelMainPage from "./pages/panel/panelMainPage";
import PanelSettingsPage from "./pages/panel/panelSettingsPage";
import SignupPage from "./pages/signupPage";
import PanelMeditationsPage from "./pages/panel/panelMeditationsPage";
import PanelGratitudeListPage from "./pages/panel/panelGratitudeListPage";
import PanelSupportPage from "./pages/panel/panelSupportPage";
import PanelTrackPage from "./pages/panel/panelTrackPage";
import PanelDailyListPage from "./pages/panel/panelDailyListPage";
import PanelYearlyListPage from "./pages/panel/panelYearlyListPage";
import PanelIntentionListPage from "./pages/panel/panelIntentionList";
import LessonListPage from "./pages/lessonListPage";
import LessonCreate from "./pages/lessonCreate";
import LessonEdit from "./pages/lessonEdit";
import IntentionList from "./pages/intentionList";
import PackageOutlet from "./pages/packageOutlet";
import AdminContextProvider from "./components/adminContextProvider";
import PackageListPage from "./pages/packageListPage";
import PackageCreate from "./pages/packageCreate";
import PackageEdit from "./pages/packageEdit";
const PrivacyPolicyPage = lazy(() => import("./pages/privacyPolicyPage"));
const TermsOfUsePage = lazy(() => import("./pages/termsOfUsePage"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/admin"
          element={
            <AdminContextProvider>
              <AdminLayout />
            </AdminContextProvider>
          }
        >
          <Route index element={<AdminLoginPage />} />
          <Route path="users" element={<AdminUsersPage />} />
          <Route path="users/:id" element={<UserInfo />}>
            <Route index element={<UserDetails />} />
            <Route path="edit" element={<UserEdit />} />
            <Route path="gratitude_list" element={<GratitudeList />} />
            <Route path="day_goals" element={<DailyList />} />
            <Route path="year_goals" element={<YearlyList />} />
            <Route path="intention_list" element={<IntentionList />} />
          </Route>
          <Route path="content" element={<ContentOutlet />}>
            <Route
              index
              element={
                <Navigate replace={true} to={"/admin/content/meditations"} />
              }
            />
            <Route path="meditations" element={<MeditationListPage />} />
            <Route path="meditations/:id" element={<MeditationEdit />} />
            <Route path="meditations/create" element={<MeditationCreate />} />
            <Route path="videos" element={<VideoListPage />} />
            <Route path="videos/:id" element={<VideoEdit />} />
            <Route path="videos/create" element={<VideoCreate />} />
            <Route path="lessons" element={<LessonListPage />} />
            <Route path="lessons/create" element={<LessonCreate />} />
            <Route path="lessons/:id" element={<LessonEdit />} />
          </Route>
          <Route path="packages" element={<PackageOutlet />}>
            <Route index element={<PackageListPage />} />
            <Route path=":id" element={<PackageEdit />} />
            <Route path=":type/:id" element={<PackageEdit />} />
            <Route path="create" element={<PackageCreate />} />
          </Route>
          <Route path="localization" element={<div>Localization</div>} />
          <Route path="*" element={<Navigate to="/admin" replace={true} />} />
        </Route>
        <Route
          path="/privacy_policy"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PrivacyPolicyPage />
            </Suspense>
          }
        />
        <Route
          path="/terms_of_use"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <TermsOfUsePage />
            </Suspense>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registration" element={<SignupPage />} />
        <Route path="/panel" element={<PanelLayout />}>
          <Route index element={<PanelMainPage />} />
          <Route path="settings" element={<PanelSettingsPage />} />
          <Route path="meditations" element={<PanelMeditationsPage />} />
          <Route path="meditations/:id" element={<PanelTrackPage />} />
          <Route path="gratitude_diary" element={<PanelGratitudeListPage />} />
          <Route path="daily_list" element={<PanelDailyListPage />} />
          <Route path="yearly_list" element={<PanelYearlyListPage />} />
          <Route path="settings/feedback" element={<PanelSupportPage />} />
          <Route path="intentions_diary" element={<PanelIntentionListPage />} />
          <Route path="*" element={<Navigate to="/login" replace={true} />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
