import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";

import { ReactComponent as ReportSvg } from "../img/report.svg";
import { ReactComponent as EditSvg } from "../img/edit.svg";
import { ReactComponent as InfoSvg } from "../img/info.svg";
import getUsers from "../data/users";
import Select from "react-dropdown-select";
import { sendFetch } from "../utils/fetch";

export default function IntentionList() {
    const location = useLocation();
    const { id } = useParams();
    const [authFail, setAuthFail] = useState(false);
    const [visibleItems, setVisibleItems] = useState(1);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [users, setUsers] = useState(null);
    const [observerDivLoaded, setObserverDivLoaded] = useState(false);

    const observerTarget = useRef(null);
    useEffect(() => {
        const getUsers = async () => {
            try {
                const formData = new FormData();
                formData.append("userId", id);
                const response = await sendFetch(
                    "intention/get-intention-list",
                    {
                        method: "POST",
                        body: formData,
                    }
                );
                if (response && response?.status === 200 && response?.data?.statusCode === 200) {
                    const json = response.data.intentionList;
                    //console.log(`Json ${json.length}`);
                    setUsers(json.reverse());
                }
            } catch (error) {
                console.error(error);
            } finally {
                setVisibleItems(20);
            }
        };
        let usersEffect = getUsers();
        //setUsers(getUsers);
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    //console.log("yo");
                    setVisibleItems(visibleItems + 30);
                }
            },
            { threshold: 1 }
        );
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [visibleItems, observerDivLoaded, users]);

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        setVisibleItems(20); // Reset visible items when performing a search
    }, []);
    let filteredItems = [];
    if (users) {
        if (users.length > 1) {
            filteredItems = users.filter((item) => {
                if (
                    item.text
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                ) {
                    return item;
                }
            });
        } else {
            filteredItems = users;
        }
    }
    useEffect(() => {
        setVisibleItems(30);
    }, [users]);

    const itemsToRender = filteredItems.slice(0, visibleItems);
    if (authFail) {
        return <Navigate to="/admin" />;
    }
    return (
        <>
            <div style={{ marginTop: 20 + "px" }}>
                <input
                    className="admin_input"
                    style={{ maxWidth: 400 + "px", marginBottom: 20 + "px" }}
                    type="text"
                    placeholder="Поиск..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </div>
            <div className="users_list_wrapper diary">
                {users &&
                    itemsToRender.map((item, index) => {
                        return (
                            <UserLine
                                text={item.text}
                                key={index}
                                index={index}
                            />
                        );
                    })}
                {users && visibleItems < users.length && (
                    <div
                        onLoad={() => {
                            setObserverDivLoaded(true);
                        }}
                        ref={observerTarget}
                    >
                        Загрузка...
                    </div>
                )}
            </div>
        </>
    );
}

function UserLine({ text, index }) {
    return (
        <div className="users_list_user_wrapper">
            <div className="users_list_left">
                <span className="users_list_user_id">{index + 1}.</span>
                <span className="users_list_user_name">
                    {text || ""}
                </span>
            </div>
        </div>
    );
}
