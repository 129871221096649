import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "../../img/cross2.svg";
import Cookies from "universal-cookie";
import ScrollContainer from "react-indiana-drag-scroll";
import {api} from "../../config/endpoints";

export default function PanelGratitudeListPage() {
    const [textValue, setTextValue] = useState("");
    const [textError, setTextError] = useState(false);
    const [listArray, setListArray] = useState([]);
    const cookies = new Cookies();
    const userId = cookies.get("user_token");
    const HandleForm = () => {
        let textErr = checkText();
        if (textErr) {
            setTextValue("");
            addPoint(textValue);
        } else {
        }
    };

    const addPoint = async (text) => {
        const response = await fetch(
            `${api}gratitude/add`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userId,
                    gratitude: text,
                }),
            }
        );
        let id = null;
        if (response.ok) {
            const json = await response.json();
            id = json;
        }
        let prevArray = listArray;
        prevArray = [{ gratitude: textValue, id: id }, ...prevArray];
        setListArray(() => {
            return prevArray;
        });
    };

    useEffect(() => {
        const getList = async () => {
            const cookies = new Cookies();
            try {
                const formData = new FormData();
                formData.append("userId", cookies.get("user_token"));
                const response = await fetch(
                    `${api}gratitude/get-all-gratitude`,
                    {
                        method: "POST",
                        body: formData,
                    }
                );
                if (response.ok) {
                    const json = await response.json();
                    setListArray(json.reverse());
                }
            } catch (error) {
                console.error(error);
            }
        };
        getList();
    }, []);

    const deleteListItem = async (index = 0, id = null) => {
        if (id) {
            const formData = new FormData();
            formData.append("id", id);
            const response = await fetch(
                `${api}gratitude/delete-by-id`,
                {
                    method: "DELETE",
                    body: formData,
                }
            );
        }
        let newList = listArray;
        newList.splice(index, 1);
        setListArray([...newList]);
    };

    const deleteList = async () => {
        const formData = new FormData();
        formData.append("userId", userId);
        const response = await fetch(
            `${api}gratitude/delete-all`,
            {
                method: "DELETE",
                body: formData,
            }
        );
        setListArray([]);
    };

    const checkText = (checked = textValue) => {
        if (checked.length > 0) {
            setTextError(false);
            return true;
        } else {
            setTextError(true);
            return false;
        }
    };

    const textChange = (e) => {
        if (textError) {
            checkText(e.target.value);
        }
        setTextValue(e.target.value);
    };

    const ListEntry = ({ text = "...", id, index }) => {
        return (
            <div className="list_entry_wrapper">
                <span className="normal_text list_entry_text">{text}</span>
                <div onClick={()=>{deleteListItem(index, id)}} className="list_cross_wrapper">
                    <Cross height={"20px"} width={"20px"} />
                </div>
            </div>
        );
    };
    return (
        <section className="panel_list_section">
            <div className="meditations_list_wrapper padding">
                <h2 className="big_title title_nowrap">
                    Дневник благодарностей
                </h2>
                <div className="list_wrapper">
                    <div className="list_input_wrapper">
                        <span className="normal_text">Благодарность</span>
                        <input
                            className="admin_input"
                            type="text"
                            placeholder="Введите благодарность"
                            onKeyDown={(e)=> {
                                if(e.key == 'Enter') {
                                    HandleForm();
                                }
                            }}
                            onChange={textChange}
                            value={textValue}
                            style={{
                                borderColor: textError ? "red" : "#05071d",
                            }}
                        />
                        <div className="list_small_text_wrapper">
                            <span onClick={deleteList} className="normal_text list_clear">
                                Очистить все
                            </span>
                        </div>
                    </div>
                    <ScrollContainer horizontal={false} className="list_scroll">
                        <div className="list_list">
                            {listArray.map((item, index) => {
                                return (
                                    <ListEntry
                                        text={item.gratitude}
                                        id={item.id}
                                        index={index}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </ScrollContainer>
                    <button
                        onClick={HandleForm}
                        className="admin_login_button list_button"
                    >
                        Добавить
                    </button>
                </div>
            </div>
        </section>
    );
}
