import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ReportSvg } from "../img/report.svg";
import { ReactComponent as EditSvg } from "../img/edit.svg";
import { ReactComponent as InfoSvg } from "../img/info.svg";
import {
    Link,
    NavLink,
    Navigate,
    Outlet,
    matchRoutes,
    useLocation,
} from "react-router-dom";
import Select from "react-dropdown-select";
import { sendFetch } from "../utils/fetch";

export default function VideoListPage() {
    const [authFail, setAuthFail] = useState(false);
    const [visibleItems, setVisibleItems] = useState(0);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [priorityArray, setPriorityArray] = useState([]);

    const [videos, setVideos] = useState([]);

    const [observerDivLoaded, setObserverDivLoaded] = useState(false);

    const observerTarget = useRef(null);
    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await sendFetch(
                    "admin/get-all-video",
                    {
                        method: "GET",
                    }
                );
                //console.log(response.status);
                if (response && response?.status === 200) {
                    const json = response.data;
                    //console.log(json);
                    //console.log('--------')
                    setVideos(json);
                    let theArray = [];
                    for (let i = 0; i < json.length; i++) {
                        theArray.push({ value: i, label: i + 1 });
                    }
                    setPriorityArray(theArray);
                }
            } catch (error) {
                console.error(error);
            }
        };
        let usersEffect = getUsers();
        //setUsers(getUsers);
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    //console.log("yo");
                    setVisibleItems(visibleItems + 30);
                }
            },
            { threshold: 1 }
        );
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [visibleItems, observerDivLoaded]);

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        setVisibleItems(20); // Reset visible items when performing a search
    }, []);
    let filteredItems = [];

    if (videos.length > 1) {
        filteredItems = videos.filter((item) => {
            if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
            }
        });
    }

    useEffect(() => {
        setVisibleItems(30);
    }, []);

    const itemsToRender = filteredItems.slice(0, visibleItems);

    if (authFail) {
        return <Navigate to="/admin" />;
    }
    return (
        <section className="meditation_section">
            <div className="meditation_search_wrapper">
                <input
                    className="admin_input"
                    style={{ maxWidth: 400 + "px", margin: 0 }}
                    type="text"
                    placeholder="Поиск..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Link
                    to="create"
                    state={{ array: videos }}
                    style={{ margin: 0 }}
                    className="admin_login_button meditation"
                >
                    Добавить
                </Link>
            </div>
            <div className="users_list_wrapper">
                {itemsToRender.map((item, index) => (
                    <MeditationLine
                        video={item}
                        key={index}
                        index={index}
                        array={videos}
                        priorityArray={priorityArray}
                    />
                ))}
                {visibleItems < videos.length && (
                    <div
                        onLoad={() => {
                            setObserverDivLoaded(true);
                        }}
                        ref={observerTarget}
                    >
                        Загрузка...
                    </div>
                )}
            </div>
        </section>
    );
}

function MeditationLine({ video, index, array, priorityArray }) {
    //console.log(video);
    const changePriority = async (e) => {
        try {
            const formData = new FormData();
            formData.append("id", video.id);
            formData.append("priority", e);
            const response = await sendFetch(
                "admin/edit-video",
                {
                    method: "POST",
                    body: formData,
                }
            );
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className="users_list_user_wrapper">
            <Link
                to={`${video.id}`}
                state={{ video: video, array: array }}
                className="users_list_left"
            >
                <span className="users_list_user_id">{index + 1}.</span>
                <span className="users_list_user_name">{video.name}</span>
            </Link>
            <div className="users_list_right">
                <Select
                    searchable={false}
                    separator
                    dropdownHandle
                    closeOnSelect
                    color="#45aff3"
                    options={priorityArray}
                    labelField="label"
                    valueField="value"
                    values={priorityArray.filter((item) => {
                        //console.log(video.priority);
                        let priority = video.priority || index
                        if (item.value === priority) {
                            return item;
                        }
                    })}
                    style={{ width: 140 + "px" }}
                    onChange={(items) => {
                        changePriority(items[0].value);
                    }}
                />
                <Link
                    className="users_list_user_button"
                    to={`${video.id}`}
                    state={{ video: video, array: array }}
                >
                    <EditSvg />
                </Link>
            </div>
        </div>
    );
}
