import React, {useContext, useEffect, useState} from "react";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";
import { ReactComponent as UsersSidebar } from "../img/usersSidebar.svg";
import { ReactComponent as ExitSidebar } from "../img/exitSidebar.svg";
import { ReactComponent as ContentSidebar } from "../img/contentSidebar.svg";
import { ReactComponent as LocalizationSidebar } from "../img/localizationSidebar.svg";
import { ReactComponent as PackageSvg } from "../img/packageSidebar.svg";
import {AdminContext} from "./adminContextProvider";
import {deleteTokenFromStorage, getValidTokenFromStorage} from "../utils/jwt";

export default function AdminLayout() {
    const [showSidebar, setShowSidebar] = useState(true);
    const location = useLocation();
    const [navLogin, setNavLogin] = useState(false);
    const { roles } = useContext(AdminContext);
    useEffect(()=>{
        const func = async () => {
            if (location.pathname === '/admin/login' || location.pathname === '/admin') {
                setShowSidebar(false);
            } else {
                setShowSidebar(true);
            }
            if (getValidTokenFromStorage()) {
                setShowSidebar(true);
            } else {
                setShowSidebar(false);
            }
        }
        func();
    }, [location.pathname, roles])
    if(location.pathname !== '/admin') {
        const token = getValidTokenFromStorage()
        if(token) {
        } else {
            return <Navigate to='/admin' replace={true} />
        }
    }
    if(location.pathname === '/admin') {
        const token = getValidTokenFromStorage();
        if(token) {
            return <Navigate to='/admin/users' replace={true} />
        }
    }
    const removeTokenFromCookies = () => {
        deleteTokenFromStorage();
        setNavLogin(true);
        window.location.reload();
    };
    return (
        <>
            <div
                className="admin_body"
                style={{
                    color: "black",
                    position: "relative",
                    minHeight: 100 + "vh",
                }}
            >
                <div className="admin_wrapper">
                    {showSidebar && <div className="sidebar_content">
                    <div className="sidebar_wrapper">
                        <div className="sidebar_top">
                            <NavLink to="/admin/users" href="/"
                                     className={({isActive}) => (isActive ? 'active sidebar_button_wrapper ' : 'sidebar_button_wrapper ')}>
                                <UsersSidebar />
                                <span className="tooltiptext">
                                    Пользователи и роли
                                </span>
                            </NavLink>
                            <NavLink to="/admin/content"
                                     className={({isActive}) => (isActive ? 'active sidebar_button_wrapper ' : 'sidebar_button_wrapper ')}>
                                <ContentSidebar />
                                <span className="tooltiptext">Контент</span>
                            </NavLink>
                            <NavLink to="/admin/packages"
                                     className={({isActive}) => (isActive ? 'active sidebar_button_wrapper withPadding' : 'sidebar_button_wrapper withPadding')}>
                                <PackageSvg />
                            </NavLink>
                            {false && <NavLink to="/admin/localization" className="sidebar_button_wrapper">
                                <LocalizationSidebar />
                                <span className="tooltiptext">Локализация</span>
                            </NavLink>}
                        </div>
                        <div
                            style={{ justifySelf: "flex-end", cursor:'pointer' }}
                            className="sidebar_button_wrapper"
                            onClick={removeTokenFromCookies}
                        >
                            <ExitSidebar />
                            {navLogin && <Navigate to='/admin' replace={true}/>}
                            <span className="tooltiptext">Выйти</span>
                        </div>
                    </div>
                    </div>}
                    <Outlet />
                </div>
            </div>
        </>
    );
}
