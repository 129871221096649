import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ReportSvg } from "../img/report.svg";
import { ReactComponent as EditSvg } from "../img/edit.svg";
import { ReactComponent as InfoSvg } from "../img/info.svg";
import {
    Link,
    NavLink,
    Navigate,
    Outlet,
    matchRoutes,
    useLocation,
} from "react-router-dom";
import Select from "react-dropdown-select";
import {api} from "../config/endpoints";
import {sendFetch} from "../utils/fetch";

export default function MeditationListPage() {
    const [authFail, setAuthFail] = useState(false);
    const [visibleItems, setVisibleItems] = useState(0);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [meditations, setMeditations] = useState([]);
    const [observerDivLoaded, setObserverDivLoaded] = useState(false);
    console.log(meditations);
    const observerTarget = useRef(null);
    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await sendFetch(
                    "admin/get-all-meditation",
                    {
                        method: "GET",
                    }
                );
                if (response.status === 200) {
                    setMeditations(response?.data);
                }
            } catch (error) {
                console.error(error);
            }
        };
        let usersEffect = getUsers();
        //setUsers(getUsers);
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    //console.log("yo");
                    setVisibleItems(visibleItems + 30);
                }
            },
            { threshold: 1 }
        );
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [visibleItems, observerDivLoaded]);

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        setVisibleItems(20); // Reset visible items when performing a search
    }, []);
    let filteredItems = [];

    if (meditations.length > 1) {
        filteredItems = meditations.filter((item) => {
            if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
            }
        });
    }

    useEffect(() => {
        setVisibleItems(30);
    }, []);

    const itemsToRender = filteredItems.slice(0, visibleItems);

    if (authFail) {
        return <Navigate to="/admin" />;
    }
    return (
        <section className="meditation_section">
            <div className="meditation_search_wrapper">
                <input
                    className="admin_input"
                    style={{ maxWidth: 400 + "px", margin: 0 }}
                    type="text"
                    placeholder="Поиск..."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Link
                    to="create"
                    style={{ margin: 0 }}
                    className="admin_login_button meditation"
                >
                    Добавить
                </Link>
            </div>
            <div className="users_list_wrapper">
                {itemsToRender.map((item, index) => (
                    <MeditationLine
                        meditation={item}
                        key={index}
                        index={index}
                    />
                ))}
                {visibleItems < meditations.length && (
                    <div
                        onLoad={() => {
                            setObserverDivLoaded(true);
                        }}
                        ref={observerTarget}
                    >
                        Загрузка...
                    </div>
                )}
            </div>
        </section>
    );
}

function MeditationLine({ meditation, index }) {
    //console.log(meditation);
    return (
        <div className="users_list_user_wrapper">
            <Link
                to={`${meditation.id}`}
                state={{ meditation: meditation }}
                className="users_list_left"
            >
                <span className="users_list_user_id">{index + 1}.</span>
                <span className="users_list_user_name">{meditation.name}</span>
            </Link>
            <div className="users_list_right">
                <Link
                    className="users_list_user_button"
                    to={`${meditation.id}`}
                    state={{ meditation: meditation }}
                >
                    <EditSvg />
                </Link>
            </div>
        </div>
    );
}
