import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Navigate, useOutletContext, useParams } from "react-router-dom";

import Select from "react-dropdown-select";
import CountryData from "../data/countries.json";
import { sendFetch } from "../utils/fetch";
import { api } from "../config/endpoints";
import { AdminContext } from "../components/adminContextProvider";

export default function UserEdit() {
  const [user, searchParams] = useOutletContext();
  const { roles, packages } = useContext(AdminContext);
  const { id } = useParams();

  const roleSelectRef = useRef(null);
  const packagesSelectRef = useRef(null);
  const [bioValue, setBioValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [instagramValue, setInstagramValue] = useState("");
  const [telegramValue, setTelegramValue] = useState("");
  const [countrySelect, setCountrySelect] = useState("");
  const [citySelect, setCitySelect] = useState("");
  const [incomeSelect, setIncomeSelect] = useState("");
  const [activitySelect, setActivitySelect] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [successful, setSuccessful] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [incomeDropdown, setIncomeDropdown] = useState([
    {
      key: 0,
      value: "<1000$",
    },
    {
      key: 1,
      value: "1000-10000$",
    },
    {
      key: 2,
      value: ">10000$",
    },
  ]);
  const [activityDropdown, setActivityDropdown] = useState([
    {
      key: 0,
      value: "Event организация",
    },
    {
      key: 1,
      value: "IT-сфера",
    },
    {
      key: 2,
      value: "Автомобили, РЖД, Авиа",
    },
    {
      key: 3,
      value: "Бухгалтерия, финансовый учет",
    },
    {
      key: 4,
      value: "Бьюти индустрия",
    },
    {
      key: 5,
      value: "Государственная служба",
    },
    {
      key: 6,
      value: "Дизайнер, художник",
    },
    {
      key: 7,
      value: "Журналистика",
    },
    {
      key: 8,
      value: "Индустрия кино",
    },
    {
      key: 9,
      value: "Инженер",
    },
    {
      key: 10,
      value: "Маркетинг",
    },
    {
      key: 11,
      value: "Медицина",
    },
    {
      key: 12,
      value: "Музыка",
    },
    {
      key: 13,
      value: "Наука, образование",
    },
    {
      key: 14,
      value: "Предприниматель",
    },
    {
      key: 15,
      value: "Продакшн, фотограф, видеограф",
    },
    {
      key: 16,
      value: "Психолог",
    },
    {
      key: 17,
      value: "Сетевой бизнес",
    },
    {
      key: 18,
      value: "Стиль, мода",
    },
    {
      key: 19,
      value: "Строительство, недвижимость",
    },
    {
      key: 20,
      value: "Тренинги, коучинг",
    },
    {
      key: 21,
      value: "Туризм",
    },
    {
      key: 22,
      value: "Фитнес, здоровье, питание",
    },
    {
      key: 23,
      value: "Флористика",
    },
    {
      key: 24,
      value: "Эзотерика",
    },
    {
      key: 25,
      value: "Юриспурденция",
    },
  ]);
  const [avatar, setAvatar] = useState(require("../img/avatar.jpg"));
  useEffect(() => {
    const getImage = async () => {
      try {
        let formData = new FormData();
        formData.append("userId", id);
        const response = await fetch(`${api}user/get-image`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const blob = await response.blob();
          const reader = new FileReader();

          reader.onload = () => {
            const base64Data = reader.result;
            setAvatar(base64Data);
          };
          reader.readAsDataURL(blob);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getImage();
  }, [user]);
  useEffect(() => {
    if (user) {
      setNameValue(user?.firstName);
      setLastNameValue(user?.lastName);
      setEmailValue(user?.email);
      setTelegramValue(user?.telegram);
      setInstagramValue(user?.instagram);
      if (user?.bio) {
        setBioValue(user?.bio);
      }
      setCountrySelect(user?.country);
      setCitySelect(user?.city);
      setIncomeSelect(user?.income);
      setActivitySelect(user?.fieldOfActivity);
      if (user?.phone) {
        setPhoneValue(user?.phone);
      }
    }
  }, [user]);
  useEffect(() => {
    console.log("user", user);
    let dropdownData = [];
    CountryData.forEach((item, index) => {
      let country = {
        key: index,
        value: item.name,
      };
      dropdownData.push(country);
    });
    setCountryDropdown(dropdownData);
    //1(dropdownData);
  }, []);

  const phoneChange = (e) => {
    if (/^[0-9 ()+-]+$/.test(e.target.value) || e.target.value.length == 0) {
      setPhoneValue(e.target.value);
    } else {
      if (phoneValue) {
        setPhoneValue(phoneValue);
      } else {
        setPhoneValue("");
      }
    }
  };

  const postSignup = async (data) => {
    try {
      const response = await fetch(`${api}user/update-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          mode: "cors",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSubmit = () => {
    if (nameValue.length > 0 && lastNameValue.length > 0) {
      let data = {
        firstName: nameValue,
        lastName: lastNameValue,
        id: id,
        email: emailValue,
        phone: phoneValue,
        instagram: instagramValue,
        telegram: telegramValue,
        country: countrySelect,
        city: citySelect,
        fieldOfActivity: activitySelect,
        income: incomeSelect,
        bio: bioValue,
        role: roleSelectRef.current.state.values?.[0].value,
        package: packagesSelectRef.current.state.values?.map((p) => p.value),
      };
      postSignup(data);
    } else {
      if (lastNameValue.length == 0) {
        // add errors
      }
      if (nameValue.length == 0) {
      }
    }
  };

  const deleteSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", id);
      const response = await sendFetch("user/delete-user", {
        method: "DELETE",
        body: formData,
      });
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (successful) {
    if (searchParams && searchParams.length > 0) {
      return <Navigate to={`/admin/users?${searchParams}`} replace={true} />;
    }
    return <Navigate to="/admin/users" replace={true} />;
  }

  return (
    <div className="user_wrapper">
      {showPopup && (
        <div
          onClick={() => {
            setShowPopup(false);
          }}
          className="popup_background"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="popup_wrapper"
          >
            <div className="popup_text user_title">Вы уверены?</div>
            <div className="popup_button_wrapper">
              <button onClick={deleteSubmit} className="admin_login_button red">
                Да
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                }}
                className="admin_login_button"
              >
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="user_avatar_wrapper">
        <div className="user_avatar">
          <img src={avatar} className="user_avatar_img" alt="avatar" />
        </div>
      </div>

      <div className="user_line_wrapper">
        <div className="user_title">id</div>
        <div className="user_value">{id}</div>

        <div className="user_title">Подписка</div>
        <Select
          ref={roleSelectRef}
          searchable={false}
          separator
          dropdownHandle
          closeOnSelect
          color="#45aff3"
          options={roles}
          labelField="label"
          valueField="value"
          values={roles.filter((item) => {
            //console.log(item.value, user?.role);
            if (item.value === user?.role) {
              return item;
            }
          })}
          style={{
            maxWidth: 500 + "px",
            border: "1px solid #05071d",
            borderRadius: 8 + "px",
            height: "41px",
            padding: 10 + "px",
          }}
        />

        <div className="user_title">Мини пакеты</div>
        <Select
          ref={packagesSelectRef}
          multi
          searchable={false}
          separator
          dropdownHandle
          closeOnSelect
          color="#45aff3"
          options={packages}
          labelField="label"
          valueField="value"
          values={packages.filter((item) => {
            //console.log(item.value, user?.role);
            if (user?.package.includes(item.value)) {
              return item;
            }
          })}
          style={{
            maxWidth: 500 + "px",
            border: "1px solid #05071d",
            borderRadius: 8 + "px",
            height: "41px",
            padding: 10 + "px",
          }}
        />

        <div className="user_title">Имя</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={nameValue}
          onChange={(e) => {
            setNameValue(e.target.value);
          }}
        />

        <div className="user_title">Фамилия</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={lastNameValue}
          onChange={(e) => {
            setLastNameValue(e.target.value);
          }}
        />

        <div className="user_title">Почта</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={emailValue}
          onChange={(e) => {
            setEmailValue(e.target.value);
          }}
        />

        <div className="user_title">Телефон</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={phoneValue}
          onChange={phoneChange}
        />

        <div className="user_title">Телеграм</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={telegramValue}
          onChange={(e) => {
            setTelegramValue(e.target.value);
          }}
        />

        <div className="user_title">Инстаграм</div>
        <input
          style={{ maxWidth: 500 + "px" }}
          className="admin_input"
          value={instagramValue}
          onChange={(e) => {
            setInstagramValue(e.target.value);
          }}
        />

        <div className="user_title">Био</div>
        <textarea
          maxLength={200}
          className="admin_input"
          value={bioValue}
          onChange={(e) => {
            setBioValue(e.target.value);
          }}
        />

        <div className="user_title">Страна</div>
        <Select
          searchable
          separator
          dropdownHandle
          closeOnSelect
          options={countryDropdown}
          color="#45aff3"
          labelField="value"
          valueField="value"
          style={{
            maxWidth: 500 + "px",
            border: "1px solid #05071d",
            borderRadius: 8 + "px",
            height: "41px",
            padding: 10 + "px",
          }}
          onChange={(items) => {
            //console.log(items[0].value)
            setCountrySelect(items[0].value);
          }}
          values={countryDropdown.filter((item) => {
            if (item.value.toLowerCase() === user?.country.toLowerCase()) {
              return item;
            }
          })}
        />

        {/*{false && (
          <>
            <div className="user_title">Город</div>
            <Select
              searchable
              separator
              dropdownHandle
              closeOnSelect
              color="#45aff3"
              style={{
                maxWidth: 500 + "px",
                border: "1px solid #05071d",
                borderRadius: 8 + "px",
                height: "41px",
                padding: 10 + "px",
              }}
              onChange={(items) => {
                setCitySelect(items[0].value);
              }}
            />
          </>
        )}*/}

        <div className="user_title">Сфера деятельности</div>
        <Select
          searchable
          separator
          dropdownHandle
          closeOnSelect
          color="#45aff3"
          options={activityDropdown}
          labelField="value"
          valueField="value"
          style={{
            maxWidth: 500 + "px",
            border: "1px solid #05071d",
            borderRadius: 8 + "px",
            height: "41px",
            padding: 10 + "px",
          }}
          onChange={(items) => {
            //console.log(items[0].value)
            setActivitySelect(items[0].value);
          }}
          values={activityDropdown.filter((item) => {
            if (
              item.value.toLowerCase() === user?.fieldOfActivity.toLowerCase()
            ) {
              return item;
            }
          })}
        />

        <div className="user_title">Доход</div>
        <Select
          searchable
          separator
          dropdownHandle
          closeOnSelect
          options={incomeDropdown}
          labelField="value"
          valueField="value"
          color="#45aff3"
          style={{
            maxWidth: 500 + "px",
            border: "1px solid #05071d",
            borderRadius: 8 + "px",
            height: "41px",
            padding: 10 + "px",
          }}
          onChange={(items) => {
            //console.log(items[0].value)
            setIncomeSelect(items[0].value);
          }}
          values={incomeDropdown.filter((item) => {
            if (item.value.toLowerCase() === user?.income.toLowerCase()) {
              return item;
            }
          })}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={formSubmit} className="admin_login_button">
            Сохранить
          </button>
        </div>
        <div></div>
        <button
          onClick={() => {
            setShowPopup(true);
          }}
          className="admin_login_button red"
          style={{ marginTop: 0 }}
        >
          Удалить
        </button>
      </div>
    </div>
  );
}
