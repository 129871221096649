import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import Preview from "../../img/preview.png";
import { ReactComponent as TriangleRight } from "../../img/triangleRight.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ReactComponent as ArrowRight } from "../../img/arrowRight.svg";
import {api} from "../../config/endpoints";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid rgba(255,255,255,.1)`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    background: "unset",
    color: "#fff",
    fontFamily: "Jost",
    borderLeft:'unset',
    borderRight:'unset'
}));
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowRight style={{opacity:.5}} height={"10px"} width={"10px"} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
    background:'none'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(255,255,255,.1)",
}));

export default function PanelMainPage() {
    const [userData, avatar] = useOutletContext();
    const [videoList, setVideoList] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const DailyButton = ({
        text = "blank",
        image,
        disabled = false,
        link = "",
    }) => {
        return (
            <Link
                className={`daily_button_link ${disabled && "disabled"}`}
                to={disabled ? "" : link}
            >
                <div
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    className="daily_button_wrapper"
                >
                    <div className="daily_button_circle">
                        <span style={{ fontSize: "20px" }}>{image}</span>
                    </div>
                    <span className="linebreak normal_text daily_button_text">
                        {text}
                    </span>
                </div>
            </Link>
        );
    };

    useEffect(() => {
        const getVideos = async () => {
            const response = await fetch(
                `${api}video/get-all-video`,
                {
                    method: "GET",
                }
            );
            if (response.ok) {
                const json = await response.json();
                let sortList = json;

                sortList.sort((p1, p2) =>
                    p1.priority < p2.priority
                        ? -1
                        : p1.priority > p2.priority
                        ? 1
                        : 0
                );
                setVideoList(sortList);
            }
        };
        getVideos();
    }, []);

    const VideoEl = ({ title = "Медитация", image = Preview, link }) => {
        return (
            <a href={link} className="daily_button_link" target="_blank">
                <div className="video_wrapper">
                    <div className="video_image_wrapper">
                        <img
                            src={image}
                            className="video_image"
                            alt={title}
                        ></img>
                    </div>
                    <span className="normal_text video_title">{title}</span>
                    <div className="video_button">
                        <TriangleRight
                            style={{ opacity: 0.5 }}
                            height={"10px"}
                            width={"10px"}
                        />
                        <span className="small_text video_button_text">
                            Смотреть
                        </span>
                    </div>
                </div>
            </a>
        );
    };

    return (
        <section className="panel_main_section">
            <h2 className="padding big_title">
                Привет, {userData.firstName || "..."} {userData.lastName || ""}
            </h2>
            <section className="daily_buttons_section">
                <h3 className="padding medium_title">Ежедневные практики</h3>
                <ScrollContainer
                    vertical={false}
                    className="padding daily_buttons_wrapper"
                >
                    <DailyButton
                        numberOfLines={2}
                        textKey="mainGratitudeDiary"
                        text={"Дневник\nблагодарностей"}
                        image="🙌"
                        link={'gratitude_diary'}
                    />
                    <DailyButton
                        numberOfLines={2}
                        textKey="mainDiaryOfIntentions"
                        text={"Дневник\nнамерений"}
                        image="🌟"
                        disabled={userData.role >= 2 ? false : true}
                        link={userData.role >= 2 && 'intentions_diary'}
                    />
                    <DailyButton
                        numberOfLines={2}
                        textKey="mainDailyPlan"
                        text={"План\nна день"}
                        image="☀️"
                        disabled={userData.role >= 2 ? false : true}
                        link={userData.role >= 2 && 'daily_list'}
                    />
                    <DailyButton
                        numberOfLines={2}
                        textKey="mainYearlyPlan"
                        text={"100 целей\nна год"}
                        image="📅"
                        disabled={userData.role >= 2 ? false : true}
                        link={userData.role >= 2 && 'yearly_list'}
                    />
                </ScrollContainer>
            </section>
            <section className="daily_buttons_section">
                <h3 className="padding medium_title">
                    Видео от Богданы Гончарук
                </h3>
                <ScrollContainer
                    vertical={false}
                    className="padding daily_buttons_wrapper"
                >
                    {videoList.map((item, index) => {
                        return (
                            <VideoEl
                                title={item.name}
                                image={item.image}
                                link={item.link}
                                key={index}
                            />
                        );
                    })}
                </ScrollContainer>
            </section>
            <section className="faq_section">
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <span className="normal_text">🖇️ С какой медитации лучше начать?</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span className="normal_text">
                            Рекомендуем начать с медитации под ваш запрос на
                            текущий момент. Финансы, здоровье, отношения или
                            проявленность. Какой выбор сделаете сегодня?
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                >
                    <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <span className="normal_text">⏰ А сколько в день можно слушать медитаций?</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span className="normal_text">
                        1-2 и более раз, главное чтобы у вас не возник перегруз или расфокус (если вы выбираете более 3х различных тем по медитациям)
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                >
                    <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <span className="normal_text">🌙 Медитацию обязательно делать сидя и с закрытыми глазами?</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span className="normal_text">
                        Нет, медитацию можно делать в движении, в пути - если вы на авто не в роли водителя и конечно же. В классическом варианте с максимальным фокусом внимания на себя.
                        </span>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                >
                    <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                    >
                        <span className="normal_text">✨ А если во время медитации все равно есть мысли?</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <span className="normal_text">
                        Ничего страшного, это вопрос времени и практики. Постепенно вы заметите что мысли становятся более спокойными и в один момент сознание станет чистым от фонового шума.
                        </span>
                    </AccordionDetails>
                </Accordion>
            </section>
        </section>
    );
}
