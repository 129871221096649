import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Select from "react-dropdown-select";

import { ReactComponent as ReportSvg } from "../../img/report.svg";
import { ReactComponent as EditSvg } from "../../img/edit.svg";
import { ReactComponent as InfoSvg } from "../../img/info.svg";
import { useAdminHooks } from "../../hooks/adminHooks";

export function UserLine({
  rolesArray,
  user,
  index,
  currentPage = 1,
  itemsPerPage = 50,
  searchParams = null,
  usersLength = 0,
  setNeedsUpdate = null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { changeRole } = useAdminHooks();
  return (
    <div className="users_list_user_wrapper">
      <Link
        to={`/admin/users/${user.id}`}
        state={{ searchParams: searchParams.toString() || "" }}
        className="users_list_left"
      >
        <span className="users_list_user_id">
          {usersLength - (index + 1 + (currentPage - 1) * itemsPerPage) + 1}.
        </span>
        <span className="users_list_user_name">{`${user.firstName} ${user.lastName}`}</span>
      </Link>
      <div className="users_list_right">
        {isLoading && <CircularProgress size={20} sx={{ color: "#45aff3" }} />}
        <Select
          searchable={false}
          separator
          dropdownHandle
          closeOnSelect
          color="#45aff3"
          options={rolesArray}
          labelField="label"
          valueField="value"
          values={rolesArray.filter((item) => {
            if (item.value === user.role) {
              return item;
            }
          })}
          style={{ width: 140 + "px" }}
          onChange={(items) => {
            changeRole(items[0].value, new Set([user.id]));
          }}
        />
        <a
          style={{ display: "none" }}
          className="users_list_user_button"
          href="/public"
        >
          <ReportSvg />
        </a>
        <Link
          className="users_list_user_button"
          to={`/admin/users/${user.id}/edit`}
          state={{ searchParams: searchParams.toString() || "" }}
        >
          <EditSvg />
        </Link>
        <Link
          className="users_list_user_button"
          to={`/admin/users/${user.id}`}
          state={{ searchParams: searchParams.toString() || "" }}
        >
          <InfoSvg />
        </Link>
      </div>
    </div>
  );
}
