import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { sendFetch } from "../utils/fetch";

export default function PackageEdit() {
  const location = useLocation();
  const { type } = useParams();
  const [pack, setPack] = useState(location.state.pack);
  const [showPopup, setShowPopup] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [intersectable, setIntersectable] = useState(false);
  useEffect(() => {
    if (pack) {
      setNameValue(pack.label);
      if (pack.intersectable) {
        setIntersectable(true);
      }
    }
  }, []);

  const putPackage = async (data) => {
    try {
      const response = await sendFetch(
        type === "mini" ? `admin/update-package` : `admin/update-role`,
        {
          method: "POST",
          body: data,
        },
      );
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSubmit = () => {
    if (nameValue.length > 0) {
      console.log("yo");
      const formData = new FormData();
      formData.append("id", pack.value);
      formData.append("name", nameValue);
      if (type !== "mini") {
        formData.append("intersectable", intersectable);
      }
      putPackage(formData);
    } else {
      if (nameValue.length == 0) {
      }
    }
  };

  const deleteSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("id", pack.value);
      const response = await sendFetch(
        type === "mini" ? `admin/delete-package` : `admin/delete-role`,
        {
          method: "DELETE",
          body: formData,
        },
      );
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (successful) {
    return <Navigate to="/admin/packages" replace={true} />;
  }

  return (
    <>
      {showPopup && (
        <div
          onClick={() => {
            setShowPopup(false);
          }}
          className="popup_background"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="popup_wrapper"
          >
            <div className="popup_text user_title">Вы уверены?</div>
            <div className="popup_button_wrapper">
              <button onClick={deleteSubmit} className="admin_login_button red">
                Да
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                }}
                className="admin_login_button"
              >
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="user_wrapper">
        <div className="user_line_wrapper">
          <div className="user_title">id</div>
          <div className="user_value">{pack.value}</div>

          <div className="user_title">Название</div>
          <input
            className="admin_input"
            value={nameValue}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={formSubmit} className="admin_login_button">
              Сохранить
            </button>
          </div>
          <div></div>
          <button
            onClick={() => {
              setShowPopup(true);
            }}
            className="admin_login_button red"
            style={{ marginTop: 0 }}
          >
            Удалить
          </button>
        </div>
      </div>
    </>
  );
}
