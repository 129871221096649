import { useEffect, useState } from "react";
import React from "react";
import "../css/styles.css";
import { Form, Link, Navigate, redirect, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { sendFetch } from "../utils/fetch";

function LoginPage() {
    const [loginVal, setLoginVal] = useState("");
    const [passwordVal, setPasswordVal] = useState("");
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const navigate = useNavigate();

    useEffect(()=> {
        const cookies = new Cookies();
        if(cookies.get('user_token')) {
            setLoginSuccess(true);
        }
    }, [])

    const loginChange = (e) => {
        setLoginVal(e.target.value);
    };
    const passwordChange = (e) => {
        setPasswordVal(e.target.value);
    };
    const postLogin = async () => {
        try {
            const response = await sendFetch(
                "user/login",
                {
                    method: "POST",
                    headers: {
                        "Content-type": "Application/json",
                    },
                    body: JSON.stringify({
                        email: loginVal,
                        password: passwordVal,
                    }),
                }
            );
            if (response && response?.status === 200) {
                const json = response.data;
                const cookies = new Cookies();
                cookies.remove("user_token", {
                    path: "/",
                });
                let now = new Date();
                let expireTime = 3600 * 6; // 6 hours in seconds
                now.setTime(now.getTime() + expireTime * 1000); // Convert seconds to milliseconds
                cookies.set("user_token", json.user.userId, {
                    maxAge: expireTime,
                    path: "/",
                });
                navigate('/panel', { replace: true });
            } else {
                setLoginError(true);
                setPasswordError(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formSubmit = (e) => {
        e.preventDefault();
        setLoginError(false);
        setPasswordError(false);
        if (loginVal.length > 0 && passwordVal.length > 0) {
            postLogin();
        } else {
            if(loginVal.length == 0) {
                setLoginError(true);
            }
            if(passwordVal.length == 0) {
                setPasswordError(true);
            }
        }
    };
    return (
        <div
            className="login_body"
            style={{ color: "black", position: "relative", minHeight: "100vh" }}
        >
            <div className="app_background"></div>
            <div>
                <form className="admin_login_wrapper">
                    <h1 className="big_title">Вход</h1>
                    <div className="admin_input_wrapper">
                        <span className="admin_login_text">Логин</span>
                        <input
                            placeholder=""
                            value={loginVal}
                            onChange={loginChange}
                            autoComplete="email"
                            style={{
                                borderColor: loginError
                                    ? "red"
                                    : "#05071d",
                            }}
                            className="admin_input"
                            id="adminLogin"
                        />
                    </div>
                    <div className="admin_input_wrapper password">
                        <span className="admin_login_text">Пароль</span>
                        <input
                            placeholder=""
                            value={passwordVal}
                            onChange={passwordChange}
                            style={{
                                borderColor: passwordError
                                    ? "red"
                                    : "#05071d",
                            }}
                            className="admin_input"
                            autoComplete="current-password"
                            id="adminPassword"
                            type="password"
                        />
                    </div>
                    <button onClick={formSubmit} className="admin_login_button">
                        Войти
                    </button>
                    <div className="login_link_wrapper">
                        <Link to="/registration" className="medium_title">
                            Зарегестрироваться
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default LoginPage;
