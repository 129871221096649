import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "../../img/cross2.svg";
import Cookies from "universal-cookie";
import ScrollContainer from "react-indiana-drag-scroll";
import { Checkbox } from "@mui/material";
import { Navigate, useOutletContext } from "react-router-dom";
import {api} from "../../config/endpoints";

export default function PanelYearlyListPage() {
    const [userData, avatar, logoutFunction] = useOutletContext();
    const [textValue, setTextValue] = useState("");
    const [textError, setTextError] = useState(false);
    const [listArray, setListArray] = useState([]);
    const cookies = new Cookies();
    const userId = cookies.get("user_token");
    const HandleForm = () => {
        let textErr = checkText();
        if (textErr) {
            setTextValue("");
            addPoint(textValue);
        } else {
        }
    };

    const addPoint = async (text) => {
        const response = await fetch(
            `${api}year-task/add-task`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userId,
                    task: text,
                    status: false,
                }),
            }
        );
        let id = null;
        if (response.ok) {
            const json = await response.json();
            id = json;
        }
        let prevArray = listArray;
        prevArray = [{ task: textValue, id: id, status: false }, ...prevArray];
        setListArray(() => {
            return prevArray;
        });
    };

    useEffect(() => {
        const getList = async () => {
            const cookies = new Cookies();
            try {
                const formData = new FormData();
                formData.append("userId", cookies.get("user_token"));
                const response = await fetch(
                    `${api}year-task/get-all-task`,
                    {
                        method: "POST",
                        body: formData,
                    }
                );
                if (response.ok) {
                    const json = await response.json();
                    setListArray(json.reverse());
                }
            } catch (error) {
                console.error(error);
            }
        };
        getList();
    }, []);

    const deleteListItem = async (index = 0, id = null) => {
        if (id) {
            const formData = new FormData();
            formData.append("id", id);
            const response = await fetch(
                `${api}year-task/delete-task`,
                {
                    method: "DELETE",
                    body: formData,
                }
            );
        }
        let newList = listArray;
        newList.splice(index, 1);
        setListArray([...newList]);
    };

    const checkText = (checked = textValue) => {
        if (checked.length > 0) {
            setTextError(false);
            return true;
        } else {
            setTextError(true);
            return false;
        }
    };

    const textChange = (e) => {
        if (textError) {
            checkText(e.target.value);
        }
        setTextValue(e.target.value);
    };

    const checkListItem = async (status, index, id)=> {
        const formData = new FormData();
            formData.append("task_id", id)
            formData.append("status", !status);
        if (id) {
            const response = await fetch(
                `${api}year-task/set-status`,
                {
                    method: "POST",
                    body: formData,
                }
            );
        }
        let newList = listArray;
        newList[index].status = !status;
        setListArray([...newList]);
    }

    const ListEntry = ({ text = "...", id, index, status = false }) => {
        const [isChecked, setIsChecked] = useState(status);
        const checkPress = () => {
            checkListItem(isChecked, index, id);
            setIsChecked(!isChecked);
        }
        return (
            <div className="list_entry_wrapper">
                <div className="list_entry_left">
                    <Checkbox
                        sx={{
                            color: "#fff",
                            "&.Mui-checked": {
                                color: 'rgb(130, 69, 198)',
                            },
                        }}
                        onChange={checkPress}
                        checked={isChecked || false}
                    />
                    <span className="normal_text list_entry_text">{text}</span>
                </div>
                <div
                    onClick={() => {
                        deleteListItem(index, id);
                    }}
                    className="list_cross_wrapper"
                >
                    <Cross height={"20px"} width={"20px"} />
                </div>
            </div>
        );
    };
    if (userData.role < 2) {
        return <Navigate to="/panel" replace={true} />;
    }
    return (
        <section className="panel_list_section">
            <div className="meditations_list_wrapper padding">
                <h2 className="big_title title_nowrap">
                    100 Целей на год
                </h2>
                <div className="list_wrapper">
                    <div className="list_input_wrapper">
                        <span className="normal_text">Цель</span>
                        <input
                            className="admin_input"
                            type="text"
                            placeholder="Введите цель"
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    HandleForm();
                                }
                            }}
                            onChange={textChange}
                            value={textValue}
                            style={{
                                borderColor: textError ? "red" : "#05071d",
                            }}
                        />
                    </div>
                    <ScrollContainer
                        horizontal={false}
                        className="list_scroll check"
                    >
                        <div className="list_list">
                            {listArray.map((item, index) => {
                                return (
                                    <ListEntry
                                        text={item.task}
                                        status={item.status}
                                        id={item.id}
                                        index={index}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </ScrollContainer>
                    <button
                        onClick={HandleForm}
                        className="admin_login_button list_button"
                    >
                        Добавить
                    </button>
                </div>
            </div>
        </section>
    );
}
