import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";
import { Navigate, useLocation } from "react-router-dom";
import { api } from "../config/endpoints";
import { AdminContext } from "../components/adminContextProvider";
import { sendFetch } from "../utils/fetch";

export default function MeditationEdit() {
  const location = useLocation();
  const [meditation, setMeditation] = useState(location.state.meditation);
  const [cover, setCover] = useState();
  const [coverName, setCoverName] = useState("");
  const [coverPhoto, setCoverPhoto] = useState(meditation.image);
  const [role, setRole] = useState(meditation.role || 0);
  const [showPopup, setShowPopup] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [bioValue, setBioValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const { roles, packages } = useContext(AdminContext);
  const packagesSelectRef = useRef(null);

  useEffect(() => {
    if (meditation.id.length > 0) {
      console.log("location is ");
      console.log(location);
      setNameValue(meditation.name);
      setBioValue(meditation.description);
    }
  }, []);

  const postSignup = async (data) => {
    try {
      const response = await sendFetch(`admin/update-meditation`, {
        method: "POST",
        body: data,
      });
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSubmit = () => {
    console.log(nameValue);
    console.log(bioValue);
    if (nameValue.length > 0 && bioValue.length > 0) {
      console.log("yo");
      const formData = new FormData();
      //console.log(cover);
      //console.log(id);
      //console.log(nameValue);
      //console.log(bioValue);
      //console.log(role);
      formData.append("id", meditation.id);
      formData.append("image", cover);
      formData.append("name", nameValue);
      formData.append("description", bioValue);
      formData.append("role", role);

      packagesSelectRef.current.state.values?.forEach(({ value }) =>
        formData.append("package", `${value}`),
      );
      postSignup(formData);
    } else {
      if (bioValue.length == 0) {
        // add errors
      }
      if (nameValue.length == 0) {
      }
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const trackRoute = e.target.value.split("\\") || "";
    const trackName = trackRoute[trackRoute.length - 1] || "a a a a";
    let trackExtension = trackName.split(".");

    trackExtension = trackExtension[trackExtension.length - 1];
    if (file) {
      const reader = new FileReader();
      setCoverName(trackName);
      const modifiedCoverFile = new File(
        [file],
        file.name.replaceAll(" ", ""),
        { type: file.type },
      );
      //console.log(modifiedCoverFile);
      setCover(modifiedCoverFile);
      setCoverName(trackExtension);
      reader.onload = (e) => {
        setCoverPhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const deleteSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("id", meditation.id);
      const response = await fetch(`${api}admin/delete-meditation`, {
        method: "DELETE",
        body: formData,
      });
      if (response.ok) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (successful) {
    return <Navigate to="/admin/content/meditations" replace={true} />;
  }

  return (
    <>
      {showPopup && (
        <div
          onClick={() => {
            setShowPopup(false);
          }}
          className="popup_background"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="popup_wrapper"
          >
            <div className="popup_text user_title">Вы уверены?</div>
            <div className="popup_button_wrapper">
              <button onClick={deleteSubmit} className="admin_login_button red">
                Да
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                }}
                className="admin_login_button"
              >
                Нет
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="user_wrapper">
        <div className="user_avatar_wrapper meditation">
          <span className="user_title" style={{ marginBottom: 10 + "px" }}>
            Обложка
          </span>
          <div className="user_avatar meditation">
            <img
              className="user_avatar_img"
              src={coverPhoto ? coverPhoto : require("../img/avatar.jpg")}
              alt="Meditation cover"
            />
          </div>
          <input
            className="meditation_file_input"
            accept=".jpg, .jpeg, .png, .heic"
            id="coverInput"
            type="file"
            onChange={handleFileChange}
          />
          <label
            className="admin_login_button meditation_file_label"
            htmlFor="coverInput"
          >
            Выбрать файл
          </label>
          <span className="admin_small_text">
            *В формате .png, .jpg, .jpeg и .heic
          </span>
        </div>
        <div className="user_line_wrapper">
          <div className="user_title">id</div>
          <div className="user_value">{meditation.id}</div>

          <div className="user_title">Название</div>
          <input
            style={{ maxWidth: 500 + "px" }}
            className="admin_input"
            value={nameValue}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
          />

          <div className="user_title">Описание</div>
          <textarea
            maxLength={200}
            className="admin_input"
            value={bioValue}
            onChange={(e) => {
              setBioValue(e.target.value);
            }}
          />

          <div className="user_title">Подписка</div>
          <Select
            separator
            dropdownHandle
            closeOnSelect
            color="#45aff3"
            options={roles}
            labelField="label"
            valueField="value"
            searchable={false}
            values={roles.filter((item) => {
              if (item.value === role) {
                return item;
              }
            })}
            style={{
              maxWidth: 500 + "px",
              border: "1px solid #05071d",
              borderRadius: 8 + "px",
              height: "41px",
              padding: 10 + "px",
            }}
            onChange={(items) => {
              setRole(items[0].value);
            }}
          />

          <div className="user_title">Мини Пакеты</div>
          <Select
            ref={packagesSelectRef}
            multi={true}
            separator
            dropdownHandle
            closeOnSelect
            color="#45aff3"
            options={packages}
            labelField="label"
            valueField="value"
            searchable={false}
            values={packages.filter((item) => {
              console.log(item.value, meditation.package);
              if (meditation.package.includes(item.value)) {
                return item;
              }
            })}
            style={{
              maxWidth: 500 + "px",
              border: "1px solid #05071d",
              borderRadius: 8 + "px",
              height: "41px",
              padding: 10 + "px",
            }}
            onChange={(items) => {
              console.log(items);
            }}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={formSubmit} className="admin_login_button">
              Сохранить
            </button>
          </div>
          <div></div>
          <button
            onClick={() => {
              setShowPopup(true);
            }}
            className="admin_login_button red"
            style={{ marginTop: 0 }}
          >
            Удалить
          </button>
        </div>
      </div>
    </>
  );
}
