import { sendFetch } from "../utils/fetch";

export function useAdminHooks() {
  const changeRole = async (newRole, userIds) => {
    // setIsLoading(true);
    try {
      const formData = new FormData();
      userIds.forEach((userId) => formData.append("userId", userId));
      formData.append("role", newRole);
      const response = await sendFetch("admin/set-role", {
        method: "POST",
        body: formData,
      });
      if (response.status === 200) {
        // setIsLoading(false);
        // setNeedsUpdate(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return { changeRole };
}
