import { useContext, useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { sendFetch } from "../utils/fetch";
import { AdminContext } from "../components/adminContextProvider";

export default function UserDetails() {
  const location = useLocation();
  const [user] = useOutletContext();
  const { id } = useParams();
  const [avatar, setAvatar] = useState(require("../img/avatar.jpg"));
  const { roles, packages } = useContext(AdminContext);
  useEffect(() => {
    const getImage = async () => {
      try {
        let formData = new FormData();
        formData.append("userId", id);
        const response = await sendFetch(`user/get-image`, {
          method: "POST",
          body: formData,
        });
        if (response && response?.status === 200) {
        }
      } catch (error) {
        console.error(error);
      }
    };
    getImage();
  }, [user]);

  return (
    <div className="user_wrapper">
      <div className="user_avatar_wrapper">
        <div className="user_avatar">
          <img src={avatar} className="user_avatar_img" alt="avatar" />
        </div>
      </div>
      <div className="user_line_wrapper">
        <div className="user_title">id</div>
        <div className="user_value">{id}</div>

        <div className="user_title">Имя</div>
        <div className="user_value">{user?.firstName}</div>

        <div className="user_title">Фамилия</div>
        <div className="user_value">{user?.lastName}</div>

        <div className="user_title">Почта</div>
        <div className="user_value">{user?.email}</div>

        <div className="user_title">Пакет</div>
        <div className="user_value">
          <NavLink to={`/admin/packages/${user?.role}`}>
            {roles?.find((pl) => user?.role === pl.value)?.label}
          </NavLink>
        </div>

        <div className="user_title">Минипакет</div>
        <div className="user_value">
          {user?.package?.map((p) => (
            <NavLink to={`/admin/packages/mini/${p}`}>
              {packages?.find((pl) => p === pl.value)?.label}
            </NavLink>
          ))}
        </div>

        <div className="user_title">Телефон</div>
        <div className="user_value">{user?.phone}</div>

        <div className="user_title">Телеграм</div>
        <div className="user_value">{user?.telegram}</div>

        <div className="user_title">Инстаграм</div>
        <div className="user_value">{user?.instagram}</div>

        <div className="user_title">Био</div>
        <span
          className="user_value"
          dangerouslySetInnerHTML={
            user?.bio > 0
              ? {
                  __html: user?.bio.replace(/\n/g, "<br />"),
                }
              : {
                  __html: "",
                }
          }
        ></span>

        <div className="user_title">Страна</div>
        <div className="user_value">{user?.country}</div>

        <div style={{ display: "none" }} className="user_title">
          Город
        </div>
        <div style={{ display: "none" }} className="user_value">
          {user?.city}
        </div>

        <div className="user_title">Доход</div>
        <div className="user_value">{user?.income}</div>

        <div className="user_title">Сфера деятельности</div>
        <div className="user_value">{user?.fieldOfActivity}</div>
      </div>
    </div>
  );
}
