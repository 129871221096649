import React from "react";

export default function Footer({black = false}) {
    return (
        <footer style={{color: black ? '#000' : 'inherit'}}>
            <div className="footer_navigation">
                <a
                    className="footer_link"
                    target="_blank"
                    href="/privacy_policy"
                >
                    Политика конфиденциальности
                </a>
                <a
                    className="footer_link"
                    target="_blank"
                    href="/terms_of_use"
                >
                    Правила использования
                </a>
            </div>
            <span className="footer_text">
                "Энергия" 2023 © Все права защищены.
            </span>
        </footer>
    );
}
