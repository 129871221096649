import React, { useEffect, useRef, useState } from "react";
import { Navigate, redirect, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
    CircularProgress,
    IconButton,
    Skeleton,
    Slider,
    capitalize,
} from "@mui/material";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";

export default function PanelTrackPage() {
    const [userData, avatar, logoutFunction, meditationList] =
        useOutletContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentTrack, setCurrentTrack] = useState(null);
    const [duration, setDuration] = useState(200);
    const [position, setPosition] = useState(0);
    const [paused, setPaused] = useState(true);
    const [canPlay, setCanPlay] = useState(false);
    const [isSeeking, setIsSeeking] = useState(false);
    const [navForward, setNavForward] = useState(false);
    const [navBack, setNavBack] = useState(false);
    const [closestTracks, setClosestTracks] = useState([]);
    const trackRef = useRef(null);
    const mainIconColor = "#fff";
    ////console.log(trackRef);
    function formatDuration(value) {
        const minute = Math.floor(value / 60);
        const secondLeft = value - minute * 60;
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    }

    const PlayButton = () => {
        if (paused) {
            trackRef.current.play();
        } else {
            trackRef.current.pause();
        }
        setPaused(!paused);
    };
    
    useEffect(() => {
        ////console.log(meditationList);
        if (meditationList.length > 1) {
            const currentTrack = meditationList.filter((item, index) => {
                if (item.id === id) {
                    setClosestTracks([meditationList[index-1]||false, meditationList[index+1]||false])
                    return item;
                }
            });
            setCurrentTrack(currentTrack[0]);
        }
    }, [meditationList, id]);

    const onPlayable = (e) => {
        setDuration(Math.floor(trackRef.current.duration));
        setCanPlay(true);
    };

    const updateTime = (e) => {
        if(canPlay && !isSeeking) {
            setPosition(Math.floor(trackRef.current.currentTime));
        }
    }
    const seekTime = (value) => {
        setIsSeeking(true);
        setPosition(value);
    }
    const seekTimeCommited = (value) => {
        setPosition(value);
        setIsSeeking(false);
        trackRef.current.currentTime = Math.floor(value);
    }
    const prevTrack = () => {
        if(closestTracks[0] && closestTracks[0].isAvailable) {
            trackRef.current.pause();
            setPaused(true);
            navigate(`/panel/meditations/${closestTracks[0].id}`, { replace: true })
        }
        else {
            const filteredItems = meditationList.filter((item, index) => {
                if (item.isAvailable) {
                    return item;
                }
            });
            if(filteredItems[filteredItems.length-1].id !== id && filteredItems[filteredItems.length-1].isAvailable) {
                trackRef.current.pause();
                setPaused(true);
                navigate(`/panel/meditations/${filteredItems[filteredItems.length-1].id}`, { replace: true })
            }
        }
    }
    const nextTrack = () => {
        ////console.log(closestTracks);
        if(closestTracks[1] && closestTracks[1].isAvailable) {
            trackRef.current.pause();
            setPaused(true);
            navigate(`/panel/meditations/${closestTracks[1].id}`, { replace: true })
        }
        else {
            if(meditationList[0].id !== id && meditationList[0].isAvailable) {
                trackRef.current.pause();
                setPaused(true);
                navigate(`/panel/meditations/${meditationList[0].id}`, { replace: true })
            }
        }
    }
    const trackEnd = (e) => {
        nextTrack();
    }

    return (
        <section className="panel_track_section">
            <div className="meditations_list_wrapper padding">
                <div className="track_top_wrapper">
                    <div className="track_cover_wrapper">
                        {currentTrack ? (
                            <img
                                className="track_cover"
                                src={currentTrack.image}
                                alt={currentTrack.name || "Cover"}
                            />
                        ) : (
                            <Skeleton
                                style={{ height: "100%", width: "100%" }}
                                variant="rounded"
                                animation="wave"
                                sx={{ bgcolor: "rgba(255, 255, 255, .1)" }}
                            ></Skeleton>
                        )}
                    </div>
                    <div className="track_description_wrapper">
                        {currentTrack ? (
                            <span className="normal_text">
                                {capitalize(currentTrack.description)}
                            </span>
                        ) : (
                            <Skeleton
                                sx={{ bgcolor: "rgba(255, 255, 255, .1)" }}
                                style={{ height: "36px", width: "100%" }}
                                variant="rounded"
                                animation="wave"
                            ></Skeleton>
                        )}
                    </div>
                </div>
                <div className="track_bottom_wrapper">
                    {currentTrack && (
                        <audio
                            onCanPlay={onPlayable}
                            ref={trackRef}
                            onTimeUpdate={updateTime}
                            className="track_audio"
                            src={currentTrack.audio}
                            onCompositionEnd={trackEnd}
                        />
                    )}
                    <span className="track_title">
                        {currentTrack ? (
                            currentTrack.name
                        ) : (
                            <Skeleton
                                sx={{ bgcolor: "rgba(255, 255, 255, .1)" }}
                                style={{
                                    width: "200px",
                                    height: "20px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                }}
                                variant="rounded"
                                animation="wave"
                            ></Skeleton>
                        )}
                    </span>
                    <div className="track_slider_wrapper">
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={position}
                            min={0}
                            step={1}
                            max={duration}
                            onChange={(_, value) => {seekTime(value)}}
                            onChangeCommitted={(_, value) => {seekTimeCommited(value)}}
                            sx={{
                                color: "#fff",
                                height: 4,
                                "& .MuiSlider-thumb": {
                                    width: 8,
                                    height: 8,
                                    transition:
                                        "0.3s cubic-bezier(.47,1.64,.41,.8)",
                                    "&:before": {
                                        boxShadow:
                                            "0 2px 12px 0 rgba(0,0,0,0.4)",
                                    },
                                    "&:hover, &.Mui-focusVisible": {
                                        boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
                                    },
                                    "&.Mui-active": {
                                        width: 20,
                                        height: 20,
                                    },
                                },
                                "& .MuiSlider-rail": {
                                    opacity: 0.28,
                                },
                            }}
                        />
                        <div className="track_time_wrapper">
                            <span className="small_text track_time_text">
                                {formatDuration(position)}
                            </span>
                            <span className="small_text track_time_text">
                                -{formatDuration(duration - position)}
                            </span>
                        </div>
                        <div className="track_controls_wrapper">
                            {navBack && closestTracks[0] && <Navigate to={`/panel/meditations/${closestTracks[0].id}`}/>}
                            <IconButton onClick={prevTrack} aria-label="previous song">
                                <FastRewindRounded
                                    sx={{ fontSize: "24px" }}
                                    htmlColor={mainIconColor}
                                />
                            </IconButton>
                            <IconButton
                                aria-label={paused ? "play" : "pause"}
                                onClick={PlayButton}
                            >
                                {canPlay ? (
                                    paused ? (
                                        <PlayArrowRounded
                                            sx={{ fontSize: "32px" }}
                                            htmlColor={mainIconColor}
                                        />
                                    ) : (
                                        <PauseRounded
                                            sx={{ fontSize: "32px" }}
                                            htmlColor={mainIconColor}
                                        />
                                    )
                                ) : (
                                    <CircularProgress />
                                )}
                            </IconButton>
                            {navForward && closestTracks[1] && <Navigate to={`/panel/meditations/${closestTracks[1].id}`}/>}
                            <IconButton onClick={nextTrack} aria-label="next song">
                                <FastForwardRounded
                                    sx={{ fontSize: "24px" }}
                                    htmlColor={mainIconColor}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
