import React, { useCallback, useRef } from "react";
import { useState, useEffect } from "react";
import { Link, Navigate, useOutletContext } from "react-router-dom";
import Cookies from "universal-cookie";
import { ReactComponent as Denied } from "../../img/denied.svg";

export default function PanelMeditationsPage() {
    const [userData, avatar, logoutFunction, meditationList] = useOutletContext();
    const cookies = new Cookies();
    const [authFail, setAuthFail] = useState(false);
    const [visibleItems, setVisibleItems] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

    const [observerDivLoaded, setObserverDivLoaded] = useState(false);

    const observerTarget = useRef(null);
    const userId = cookies.get("user_token");

    //console.log(meditationList);
    useEffect(() => {
        
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setVisibleItems(visibleItems + 30);
                }
            },
            { threshold: 1 }
        );
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [visibleItems, observerDivLoaded, meditationList]);

    useEffect(() => {
        setVisibleItems(30);
    }, [meditationList]);
    let itemsToRender = [];
    if(meditationList.length > 0) {
        itemsToRender = meditationList.slice(0, visibleItems);
    }

    return (
        <section className="panel_meditations_section">
            <div className="meditations_text_wrapper padding">
                <h1 className="big_title">МЕДИТАЦИИ</h1>
                <span className="normal_text sub_text">
                    Здесь собраны все медитации, которые тебе доступны на данный
                    момент
                </span>
            </div>
            {meditationList.length > 0 && <div className="meditations_list_wrapper padding">
                {meditationList.length === 1 ? (<Track title={meditationList[0].name} trackId={meditationList[0].id} data={meditationList[0]} disabled={!meditationList[0].isAvailable} audio={meditationList[0].audio} id={0} index={0} />) : itemsToRender.map((item, index) => {
                    ////console.log('yo');
                    return (
                        <Track title={item.name} trackId={item.id} data={item} disabled={!item.isAvailable} audio={item.audio} key={index} id={index} />
                    )
                })}
                {visibleItems < meditationList.length && (
                    <div
                        onLoad={() => {
                            setObserverDivLoaded(true);
                        }}
                        ref={observerTarget}
                    >
                        Загрузка...
                    </div>
                )}
            </div>}
        </section>
    );
}

const Track = ({
    title = "Blank",
    disabled = false,
    data = {},
    id = 0,
    audio = null,
    trackId = "",
}) => {
    return (
    <div className={`meditation_wrapper${disabled ? ' disabled' : ''}`}>
        <Link to={`${trackId}`} className="meditation_button_wrapper">
            <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13 23.5536C13 25.0893 14.659 26.052 15.9923 25.2901L25.7111 19.7365C27.0548 18.9687 27.0548 17.0313 25.7111 16.2635L15.9923 10.7099C14.659 9.94798 13 10.9107 13 12.4464V23.5536Z"
                    fill="white"
                />
            </svg>
        </Link>
        <div className="meditation_right">
            <span className="normal_text meditation_title">{title}</span>
            <div className="meditation_icon_wrapper">
                {disabled && <Denied height={"20px"} width={"20px"} />}
            </div>
        </div>
    </div>
    )
};
