import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { sendFetch } from "../utils/fetch";

export default function PackageCreate() {
  const [nameValue, setNameValue] = useState("");
  const [intersectable, setIntersectable] = useState(true);
  const [miniPackage, setMiniPackage] = useState(true);
  const [successful, setSuccessful] = useState(false);

  const postSignup = async (data) => {
    try {
      const response = await sendFetch(
        miniPackage ? `admin/add-package` : `admin/add-role`,
        {
          method: "POST",
          body: data,
        },
      );
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSubmit = () => {
    if (nameValue.length > 0) {
      const formData = new FormData();
      formData.append("name", nameValue);
      if (!miniPackage) formData.append("intersectable", intersectable);
      postSignup(formData);
    } else {
      if (nameValue.length == 0) {
      }
    }
  };

  if (successful) {
    return <Navigate to="/admin/packages" replace={true} />;
  }

  return (
    <>
      <div className="user_wrapper">
        <div className="user_line_wrapper">
          <div className="user_title">
            Создание {intersectable ? "минипакета" : "пакета"}
          </div>
          <input
            type="checkbox"
            className="admin_input"
            checked={miniPackage}
            onChange={(e) => {
              setMiniPackage(!miniPackage);
            }}
          />
          <div className="user_title">Название</div>
          <input
            className="admin_input"
            value={nameValue}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={formSubmit} className="admin_login_button">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
