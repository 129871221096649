import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, Navigate, useNavigate, useOutletContext } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import BlankAvatar from "../../img/avatar.jpg";
import { ReactComponent as ArrowRight } from "../../img/arrowRight.svg";
import Cookies from "universal-cookie";
import {ReactComponent as TelegramIcon} from "../../img/telegram2.svg";
import {ReactComponent as InstagramIcon} from "../../img/instagram.svg";

export default function PanelSettingsPage() {
    const [userData, avatar, logoutFunction] = useOutletContext();
    const [navLogin, setNavLogin] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const navigate = useNavigate();
    const Separator = () => (
        <div
            style={{
                width: "100%",
                height: 1,
                backgroundColor: "rgba(255,255,255,.1)",
            }}
        ></div>
    );

    const FaqEl = ({ text = "😃 Blank", onPress = () => {} }) => {
        return (
            <div onClick={onPress} className="faq_line">
                <span className="normal_text faq_text">{text}</span>
                <ArrowRight style={{ opacity: 0.5 }} height={10} width={10} />
            </div>
        );
    };

    const NavSupport = () => {
        navigate('feedback');
    }

    return (
        <section className="panel_settings_section">
            {showContacts && <div onClick={()=>{setShowContacts(false)}} className="modal_background">
                <div onClick={(e)=>{e.stopPropagation()}} className="modal_wrapper">
                    <h2 className="medium_title">Где нас искать?</h2>
                    <div className="modal_lines_wrapper">
                        <Link to='https://instagram.com/bohdana_goncharuk?igshid=NTc4MTIwNjQ2YQ==' target="_blank" className="modal_line">
                            <div className="modal_line_left">
                                <div className="modal_line_icon_wrapper">
                                    <InstagramIcon height={'24px'} width={'24px'} />
                                </div>
                                <div className="normal_text modal_line_title">Instagram</div>
                            </div>
                            <div className="modal_line_right">
                                <div className="normal_text modal_line_text">@bohdana_goncharuk</div>
                                <div className="modal_line_arrow_wrapper">
                                <ArrowRight style={{ opacity: 0.5 }} height={10} width={10} />
                                </div>
                            </div>
                        </Link>
                        <Link to='https://t.me/+G2q9WK-4nN00ZTMy' target="_blank" className="modal_line">
                            <div className="modal_line_left">
                                <div className="modal_line_icon_wrapper">
                                    <TelegramIcon height={'24px'} width={'24px'} />
                                </div>
                                <div className="normal_text modal_line_title">Instagram</div>
                            </div>
                            <div className="modal_line_right">
                                <div className="normal_text modal_line_text"></div>
                                <div className="modal_line_arrow_wrapper">
                                <ArrowRight style={{ opacity: 0.5 }} height={10} width={10} />
                                </div>
                            </div>
                        </Link>
                    </div>
                    <button onClick={()=>{setShowContacts(false)}} className="modal_button medium_title">Закрыть</button>
                </div>
            </div>}
            <div className="settings_user_wrapper">
                <div className="settings_avatar_wrapper">
                    <img
                        src={avatar || BlankAvatar}
                        alt="Фото"
                        className="settings_avatar"
                    />
                </div>
                <h2 className="big_title settings_username">
                    {userData.firstName} {userData.lastName}
                </h2>
            </div>
            <div className="button_list_wrapper padding">
                <FaqEl
                    textKey="settingsContactsTitle"
                    onPress={() => {
                        setShowContacts(true);
                    }}
                    text="Где нас искать?"
                />
                <Separator />
                <FaqEl
                    textKey="settingsFeedbackTitle"
                    onPress={NavSupport}
                    text="Анкета обратной связи"
                />

                <Separator />
                <FaqEl
                    textKey="settingsExitTitle"
                    onPress={logoutFunction}
                    text="Выйти из приложения"
                />
            </div>
        </section>
    );
}
