import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";
import {
  NavLink,
  Navigate,
  Outlet,
  matchRoutes,
  useLocation,
  useParams,
  useOutletContext,
} from "react-router-dom";
import { api } from "../config/endpoints";
import { AdminContext } from "../components/adminContextProvider";
import { sendFetch } from "../utils/fetch";

export default function MeditationCreate() {
  const location = useLocation();
  const [meditation, setMeditation] = useState({});
  const [cover, setCover] = useState();
  const [coverPhoto, setCoverPhoto] = useState();
  const [coverName, setCoverName] = useState("");
  const [trackName, setTrackName] = useState();
  const [track, setTrack] = useState();

  const [bioValue, setBioValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [trackError, setTrackError] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const { roles, packages } = useContext(AdminContext);

  const [role, setRole] = useState(0);
  const [pack, setPack] = useState(0);
  const packagesSelectRef = useRef(null);

  const postSignup = async (data) => {
    try {
      const response = await sendFetch(`admin/add-meditation`, {
        method: "POST",
        body: data,
      });
      if (response.status === 200) {
        setSuccessful(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formSubmit = () => {
    if (nameValue.length > 0 && bioValue.length > 0 && track && cover) {
      const formData = new FormData();
      //console.log(cover);
      //console.log(track);
      //console.log(nameValue);
      //console.log(bioValue);
      //console.log(role);
      formData.append("image", cover);
      formData.append("audio", track);
      formData.append("name", nameValue);
      formData.append("description", bioValue);
      formData.append("role", role);

      packagesSelectRef.current.state.values?.forEach(({ value }) =>
        formData.append("package", `${value}`),
      );
      postSignup(formData);
    } else {
      if (bioValue.length == 0) {
        // add errors
      }
      if (nameValue.length == 0) {
      }
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const trackRoute = e.target.value.split("\\") || "";
    const trackName = trackRoute[trackRoute.length - 1] || "a a a a";
    let trackExtension = trackName.split(".");

    trackExtension = trackExtension[trackExtension.length - 1];
    if (file) {
      const reader = new FileReader();
      setCoverName(trackName);
      const modifiedCoverFile = new File(
        [file],
        file.name.replaceAll(" ", ""),
        { type: file.type },
      );
      //console.log(modifiedCoverFile);
      setCover(modifiedCoverFile);
      setCoverName(trackExtension);
      reader.onload = (e) => {
        setCoverPhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleTrackChange = async (e) => {
    const file = e.target.files[0];
    const trackRoute = e.target.value.split("\\") || "";
    const trackName = trackRoute[trackRoute.length - 1] || "a a a a";
    //console.log(file);
    if (trackName.split(" ").length === 1 && file) {
      setTrackError(false);
      setTrackName(trackName);
      setTrack(file);
    } else {
      setTrackError(true);
    }
  };

  if (successful) {
    return <Navigate to="/admin/content/meditations" replace={true} />;
  }

  return (
    <>
      <div className="user_wrapper">
        <div className="user_avatar_wrapper meditation">
          <span className="user_title" style={{ marginBottom: 10 + "px" }}>
            Обложка
          </span>
          <div className="user_avatar meditation">
            <img
              className="user_avatar_img"
              src={coverPhoto ? coverPhoto : require("../img/avatar.jpg")}
              alt="Meditation cover"
            />
          </div>
          <input
            className="meditation_file_input"
            accept=".jpg, .jpeg, .png, .heic"
            id="coverInput"
            type="file"
            onChange={handleFileChange}
          />
          <label
            className="admin_login_button meditation_file_label"
            htmlFor="coverInput"
          >
            Выбрать файл
          </label>
          <span className="admin_small_text">
            *В формате .png, .jpg, .jpeg и .heic
          </span>
        </div>
        <div className="meditation_track_wrapper">
          <span className="user_title">Медитация</span>
          <span className="user_text file_text">
            {trackName ? "" + trackName : "Файл не выбран"}
          </span>
          <input
            className="meditation_file_input"
            accept=".mp3"
            id="trackInput"
            type="file"
            onChange={handleTrackChange}
          />
          <label
            style={{ marginTop: 10 + "px", marginBottom: 2 + "px" }}
            className="admin_login_button meditation_file_label"
            htmlFor="trackInput"
          >
            Выбрать файл
          </label>
          <span
            style={{ color: trackError ? "red" : "rgba(0,0,0,.5)" }}
            className="admin_small_text"
          >
            *В формате .mp3 с именем файла без пробелов и кириллицы.
          </span>
        </div>
        <div className="user_line_wrapper">
          <div className="user_title">Название</div>
          <input
            style={{ maxWidth: 500 + "px" }}
            className="admin_input"
            value={nameValue}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
          />

          <div className="user_title">Описание</div>
          <textarea
            maxLength={200}
            className="admin_input"
            value={bioValue}
            onChange={(e) => {
              setBioValue(e.target.value);
            }}
          />

          <div className="user_title">Подписка</div>
          <Select
            ref={packagesSelectRef}
            searchable={false}
            separator
            dropdownHandle
            closeOnSelect
            color="#45aff3"
            options={roles}
            labelField="label"
            valueField="value"
            values={roles.filter((item) => {
              if (item.value === role) {
                return item;
              }
            })}
            style={{
              maxWidth: 500 + "px",
              border: "1px solid #05071d",
              borderRadius: 8 + "px",
              height: "41px",
              padding: 10 + "px",
            }}
            onChange={(items) => {
              setRole(items[0].value);
            }}
          />

          <div className="user_title">Мини Пакет</div>
          <Select
            ref={packagesSelectRef}
            multi={true}
            searchable={false}
            separator
            dropdownHandle
            closeOnSelect
            color="#45aff3"
            options={packages}
            labelField="label"
            valueField="value"
            values={packages.filter((item) => {
              if (item.value === role) {
                return item;
              }
            })}
            style={{
              maxWidth: 500 + "px",
              border: "1px solid #05071d",
              borderRadius: 8 + "px",
              height: "41px",
              padding: 10 + "px",
            }}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={formSubmit} className="admin_login_button">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
