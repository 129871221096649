import React, {useContext, useEffect, useState} from "react";
import Select from "react-dropdown-select";
import {
    Navigate,
    useLocation,
    useParams,
} from "react-router-dom";
import { sendFetch } from "../utils/fetch";
import {AdminContext} from "../components/adminContextProvider";

export default function LessonEdit() {
    const location = useLocation();
    const [video, setVideo] = useState(location.state.video);
    const [cover, setCover] = useState();
    const [role, setRole] = useState();
    const [priorityArray, setPriorityArray] = useState([]);
    const [videos, setVideos] = useState(location.state.array);
    const [nameValue, setNameValue] = useState("");
    const [linkValue, setLinkValue] = useState("");
    const [coverPhoto, setCoverPhoto] = useState(video.image);
    const [coverName, setCoverName] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const { roles } = useContext(AdminContext);
    useEffect(() => {
        let theArray = [];
        for (let i = 0; i < videos.length; i++) {
            theArray.push({ value: i, label: i + 1 });
        }
        setPriorityArray(theArray);
    }, []);
    useEffect(() => {
        if (video.id.length > 0) {
            //console.log(video);
            setNameValue(video.name);
            setLinkValue(video.link);
        }
    }, []);

    const { id } = useParams();

    const postSignup = async (data) => {
        try {
            const response = await sendFetch(
                "admin/lessons/edit-lesson",
                {
                    method: "POST",
                    body: data,
                }
            );
            if (response && response?.status === 200 && response?.data?.statusCode === 200) {
                setSuccessful(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formSubmit = () => {
        if (nameValue?.length > 0 && linkValue?.length > 0) {
            const formData = new FormData();
            formData.append("id", video.id);
            formData.append("name", nameValue);
            formData.append("link", linkValue);
            if (cover) {
                formData.append("image", cover);
            }
            formData.append("role", role);
            postSignup(formData);
        } else {
            if (nameValue?.length == 0) {
            }
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const trackRoute = e.target.value.split("\\") || "";
        const trackName = trackRoute[trackRoute.length - 1] || "";
        let trackExtension = trackName.split(".");

        trackExtension = trackExtension[trackExtension.length - 1];
        if (file) {
            const reader = new FileReader();
            setCoverName(trackName);
            const modifiedCoverFile = new File(
                [file],
                file.name.replaceAll(" ", ""),
                { type: file.type }
            );
            //console.log(modifiedCoverFile);
            setCover(modifiedCoverFile);
            setCoverName(trackExtension);
            reader.onload = (e) => {
                setCoverPhoto(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const deleteSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("id", video.id);
            const response = await sendFetch(
                "admin/lessons/delete-lesson",
                {
                    method: "DELETE",
                    body: formData,
                }
            );
            if (response && response?.status === 200 && response?.data?.statusCode === 200) {
                setSuccessful(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (successful) {
        return <Navigate to="/admin/content/lessons" replace={true} />;
    }

    return (
        <>
            {showPopup && (
                <div
                    onClick={() => {
                        setShowPopup(false);
                    }}
                    className="popup_background"
                >
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="popup_wrapper"
                    >
                        <div className="popup_text user_title">Вы уверены?</div>
                        <div className="popup_button_wrapper">
                            <button
                                onClick={deleteSubmit}
                                className="admin_login_button red"
                            >
                                Да
                            </button>
                            <button
                                onClick={() => {
                                    setShowPopup(false);
                                }}
                                className="admin_login_button"
                            >
                                Нет
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="user_wrapper">
                <div className="user_avatar_wrapper meditation">
                    <span
                        className="user_title"
                        style={{ marginBottom: 10 + "px" }}
                    >
                        Обложка
                    </span>
                    <div className="user_avatar video">
                        <img
                            className="user_avatar_img"
                            src={
                                coverPhoto
                                    ? coverPhoto
                                    : require("../img/avatar.jpg")
                            }
                            alt="Video cover"
                        />
                    </div>
                    <input
                        className="meditation_file_input"
                        accept=".jpg, .jpeg, .png, .heic"
                        id="coverInput"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label
                        className="admin_login_button meditation_file_label"
                        htmlFor="coverInput"
                    >
                        Выбрать файл
                    </label>
                    <span className="admin_small_text">
                        *В формате .png, .jpg, .jpeg и .heic
                    </span>
                </div>
                <div className="user_line_wrapper">
                    <div className="user_title">id</div>
                    <div className="user_value">{video.id}</div>

                    <div className="user_title">Название</div>
                    <input
                        style={{ maxWidth: 500 + "px" }}
                        className="admin_input"
                        value={nameValue}
                        onChange={(e) => {
                            setNameValue(e.target.value);
                        }}
                    />

                    <div className="user_title">Ссылка</div>
                    <input
                        style={{ maxWidth: 500 + "px" }}
                        className="admin_input"
                        value={linkValue}
                        onChange={(e) => {
                            setLinkValue(e.target.value);
                        }}
                    />

                    <div className="user_title">Подписка</div>
                    <Select
                        separator
                        dropdownHandle
                        closeOnSelect
                        color="#45aff3"
                        options={roles}
                        labelField="label"
                        valueField="value"
                        searchable={false}
                        values={roles.filter((item) => {
                            if (item.value === video?.role) {
                                return item;
                            }
                        })}
                        style={{
                            maxWidth: 500 + "px",
                            border: "1px solid #05071d",
                            borderRadius: 8 + "px",
                            height: "41px",
                            padding: 10 + "px",
                        }}
                        onChange={(items) => {
                            setRole(items[0].value);
                        }}
                    />
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            onClick={formSubmit}
                            className="admin_login_button"
                        >
                            Сохранить
                        </button>
                    </div>
                    <div></div>
                    <button
                        onClick={() => {
                            setShowPopup(true);
                        }}
                        className="admin_login_button red"
                        style={{ marginTop: 0 }}
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </>
    );
}
