import { useState } from "react";
import React from "react";
import "../css/styles.css";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {api} from "../config/endpoints";

function AdminLoginPage() {
    const [loginVal, setLoginVal] = useState("");
    const [passwordVal, setPasswordVal] = useState("");
    const [loginSuccess, setLoginSuccess] = useState(false);

    const loginChange = (e) => {
        setLoginVal(e.target.value);
    };
    const passwordChange = (e) => {
        setPasswordVal(e.target.value);
    };
    const postLogin = async () => {
        try {
            const response = await fetch(
                `${api}admin/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-type" : "Application/json"
                    },
                    body: JSON.stringify({
                        email: loginVal,
                        password: passwordVal
                    }),
                }
            );
            if (response.ok) {
                const json = await response.json();
                localStorage.setItem("token", json.token);
                setLoginSuccess(true);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const formSubmit = (e) => {
        e.preventDefault();
        if (loginVal.length > 0 && passwordVal.length > 0) {
            postLogin();
        }
    };
    return (
        <div
            className="admin_body login_body"
            style={{ color: "black", position: "relative", minHeight: "100vh" }}
        >
            {loginSuccess && <Navigate to="/admin/users" />}
            <div className="background"></div>
            <div>
                <form className="admin_login_wrapper">
                    <div className="admin_input_wrapper">
                        <span className="admin_login_text">Логин</span>
                        <input
                            placeholder=""
                            value={loginVal}
                            onChange={loginChange}
                            autoComplete="email"
                            className="admin_input"
                            id="adminLogin"
                        />
                    </div>
                    <div className="admin_input_wrapper password">
                        <span className="admin_login_text">Пароль</span>
                        <input
                            placeholder=""
                            value={passwordVal}
                            onChange={passwordChange}
                            className="admin_input"
                            autoComplete="current-password"
                            id="adminPassword"
                            type="password"
                        />
                    </div>
                    <button onClick={formSubmit} className="admin_login_button">
                        Войти
                    </button>
                </form>
            </div>
        </div>
    );
}
export default AdminLoginPage;
