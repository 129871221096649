import React from "react";
import {NavLink, Outlet} from "react-router-dom";

export default function ContentOutlet() {
    return (
        <section className="users_section">
            <h1>Контент</h1>
            <div className="content_navigation_wrapper">
                <NavLink className="content_navigation_link" to="meditations">Медитации</NavLink>
                <NavLink className="content_navigation_link" to="videos">Видео</NavLink>
                <NavLink className="content_navigation_link" to="lessons">Уроки</NavLink>
            </div>
            <Outlet/>
        </section>
    );
}
