import React, { useContext } from "react";
import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-dropdown-select";
import { Pagination } from "@mui/material";
import { sendFetch } from "../../utils/fetch";
import { AdminContext } from "../../components/adminContextProvider";
import { UserLine } from "../userLine/userLine";
import { useAdminHooks } from "../../hooks/adminHooks";
import styles from "./styles.module.css";

export default function AdminUsersPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [needsUpdate, setNeedsUpdate] = useState(true);
  const [checkedIds, setCheckedIds] = useState(new Set());
  const { roles } = useContext(AdminContext);
  const { changeRole } = useAdminHooks();
  let itemsPerPage = 50;
  let searchQuery = searchParams.get("search") || "";
  let packageSearch = searchParams.get("packageSearch") || "";
  let currentPage = parseInt(searchParams.get("page")) || 1;
  useEffect(() => {
    console.log("page");
  }, [searchParams]);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await sendFetch("admin/get-all-users", {
          headers: {
            mode: "cors",
          },
          method: "GET",
        });
        //console.log(response.status);
        if (response.status === 200) {
          setUsers(response.data.reverse());
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (needsUpdate) {
      setNeedsUpdate(false);
      getUsers();
    }
  }, [needsUpdate]);

  const handleSearch = useCallback((query) => {
    searchParams.delete("page");
    if (query.length === 0) {
      searchParams.delete("search");
    } else {
      searchParams.set("search", query);
    }
    setSearchParams(searchParams);
  }, []);

  const handlePackageSearch = useCallback((query) => {
    searchParams.delete("page");
    if (query.length === 0) {
      searchParams.delete("packageSearch");
    } else {
      searchParams.set("packageSearch", query);
    }
    setSearchParams(searchParams);
  }, []);
  let filteredItems = [];
  if (users.length > 0) {
    if (users.length > 1 && users[0].firstName) {
      filteredItems = users
        .filter((item) => {
          return packageSearch.length ? item.role === +packageSearch : true;
        })
        .filter((item) => {
          let fullName = `${item.firstName} ${item.lastName}`;
          if (
            fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.email.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return item;
          } else if (
            item.phone &&
            item.phone.length > 0 &&
            item.phone.includes(searchQuery)
          ) {
            return item;
          } else if (
            item.instagram &&
            item.instagram.length > 0 &&
            item.instagram.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return item;
          } else if (
            item.telegram &&
            item.telegram.length > 0 &&
            item.telegram.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return item;
          }
        });
    }
  }

  const itemsToRender = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
  const handlePageChange = (e, page) => {
    if (page === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", page);
    }
    setSearchParams(searchParams);
  };

  return (
    <section className="users_section">
      <h1>Пользователи и роли</h1>
      <div className="users_filters">
        <input
          className="admin_input"
          type="text"
          placeholder="Поиск..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <select
          className="admin_select"
          onChange={(e) => handlePackageSearch(e.target.value)}
        >
          {[{ value: "", label: "Показывать все пакеты" }, ...roles].map(
            (item, index) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ),
          )}
        </select>
      </div>
      <div className="users_filters bulk_update">
        <input
          type="checkbox"
          className="admin_input"
          checked={itemsToRender.length === checkedIds.size}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedIds(new Set(itemsToRender.map((u) => u.id)));
            } else {
              setCheckedIds(new Set());
            }
          }}
        />
        <Select
          class="admin_select"
          searchable={false}
          separator
          dropdownHandle
          closeOnSelect
          placeholder="Выбор пакета"
          color="#45aff3"
          options={roles}
          labelField="label"
          valueField="value"
          style={{ width: 140 + "px" }}
          onChange={(items) => {
            if (checkedIds.size) {
              changeRole(items[0].value, checkedIds);
              setUsers(
                users.map((u) => {
                  u.role = items[0].value;
                  return u;
                }),
              );
            }
          }}
        />
      </div>
      <div className="users_list_wrapper">
        {itemsToRender.map((item, index) => {
          return (
            <div className={styles.parent_row} key={item.userid}>
              <input
                type="checkbox"
                className="admin_input"
                checked={checkedIds.has(item.id)}
                onChange={(e) => {
                  const newCheckedIds = new Set(checkedIds);
                  if (newCheckedIds.has(item.id)) {
                    newCheckedIds.delete(item.id);
                  } else {
                    newCheckedIds.add(item.id);
                  }
                  setCheckedIds(newCheckedIds);
                }}
              />
              <UserLine
                rolesArray={roles}
                setNeedsUpdate={setNeedsUpdate}
                usersLength={filteredItems.length}
                user={item}
                index={index}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                searchParams={searchParams}
              />
            </div>
          );
        })}
        <div className="pagination_wrapper">
          {filteredItems.length > itemsPerPage && (
            <Pagination
              page={currentPage || 1}
              onChange={handlePageChange}
              count={Math.ceil(filteredItems.length / itemsPerPage) || 1}
            />
          )}
        </div>
      </div>
    </section>
  );
}
