import {jwtDecode} from 'jwt-decode';

// Function to check token validity and claims
export const isTokenValid = (token, checkRole = "Admin") => {
    if (!token) {
        return false; // No token provided
    }

    try {
        // Decode the token
        const decodedToken = jwtDecode(token);

        // Check expiration
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
            console.warn('Token has expired.');
            return false; // Token has expired
        }

        // Validate specific claims (example)
        //@todo return after fixing backend
        if (!decodedToken.UserRole || decodedToken.UserRole !== checkRole) {
            console.warn('User does not have the required role.');
            return false; // Invalid role claim
        }

        // Token is valid and claims are valid
        return true;
    } catch (error) {
        console.error('Invalid token:', error);
        return false; // Token is invalid
    }
};

export const getValidTokenFromStorage = () => {
    const token = localStorage.getItem("token");
    if (isTokenValid(token)) {
        return token;
    }
    return null;
}
export const deleteTokenFromStorage = () => localStorage.removeItem("token")
export const setTokenToStorage = (token) => localStorage.setItem("token", token)
